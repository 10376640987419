import React, { useEffect, useRef, useState } from 'react'
import ChatMessages from './ChatMessages'
import { useLocation } from 'react-router-dom';

const ChatDetails = ({ chatId }) => {
        const [text, setText] = useState('');
        const [conversationWith, setConversationWith] = useState(null);

        const conversation = useRef('');
        
        
        const location = useLocation();
        let l = location.pathname.split('/')[2]


        
        if(conversationWith){
                const chat = conversation.current
                
        }
        useEffect(() => {
                console.log('chat id of person is----------', chatId);
                setConversationWith(l)
        }, [chatId]);
        
        const [messages, setMessages] = useState([
                {
                        sender_id: 1,
                        message: "hello, whatsupp man!",
                        time: "09:32am"
                },
                {
                        sender_id: 2,
                        message: "hello, whatsupp man! hello, whatsupp man! estw hello, whatsupp man! hello, whatsupp man!",
                        time: "09:32am"
                },
                {
                        sender_id: 1,
                        message: "hello, whatsupp man!",
                        time: "09:32am"
                },
                {
                        sender_id: 1,
                        message: "hello, whatsupp man!",
                        time: "09:32am"
                },
                
        ]);
        const sendMessage = (e) => {
                e.preventDefault();
        }
        
  return (
        <>
                <div className='messageSection'>
                        <ChatMessages messages={messages} />
                </div>
                <div className='chat_messageInput'>
                        <div onSubmit={sendMessage} className='chat_input' method='get'>
                                <textarea onChange={e => {setText(e.target.value)}} placeholder='Type a message'></textarea>
                        </div>
                        <button  className='send_message_btn' type='submit'>Send</button>
                </div>
        </>
  )
}

export default ChatDetails
