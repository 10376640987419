import React from 'react'
import { useTranslation } from 'react-i18next'
import { values, team } from './location/values'

const TeamSection = () => {
  const { t } = useTranslation()
  return (
    <div className='WhatWeDoSection'>
            <h2 className='section_title'>{ t('about_page.team')}</h2>
            <div className='team-grid'>
                  {team.map((team, index) => (
                    <div className='display-card'>
                         <div className='team-card' key={team.id}>
                            <img src={team.image}/>
                         </div>
                         <h2>{team.name}</h2>
                    </div>
                      
                  ))}
            </div>
    </div>
  )
}

export default TeamSection