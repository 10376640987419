import React, { useContext, useEffect, useState } from 'react'
import './Cart.css'
import { BsShieldCheck } from 'react-icons/bs'
import CartItem from './CartItem'
import { useNavigate } from 'react-router-dom'
import Popup from '../Addons/Popup'
import { WishListContext } from '../../Context/WishListContext'

const WishList = () => {
	const [wishList, setwishList] = useContext(WishListContext)
	const [total, setTotal] = useState(0)
    const [wishListAdded, setWishListAdded] = useState(false)
    const [wishListRemoved, setWishListRemoved] = useState(false)
	const navigate = useNavigate()
	
	const removeProduct = (id) => {
		setwishList(
			wishList.filter(item => item.slug !== id)
		)
	}

    const removePopup = () => {
		setWishListAdded(false)
		setWishListRemoved(false)
	}
	const animateWishListRemoved = () => {
		setWishListRemoved(true)
		setTimeout(() => {
			setWishListRemoved(false)
		}, 3000)
	}
	const animateWishListAdded = () => {
		setWishListAdded(true)
		setTimeout(() => {
			setWishListAdded(false)
		}, 3000);
	}
	const wishListChanged = (item) => {
        removeProduct(item.slug)
		animateWishListRemoved()
	}

	useEffect(() => {
	  return () => {
		getTotal()
	  }
	}, [wishList])
	

	const getTotal = () => {
		let total = 0;
		wishList.map( item =>
			total += Number(item.price)
		)
		setTotal(total)
	}
    
	return (
		<div className='container'>
			<div className='cart'>
				<div className='cart_list_section'>
					<div className='cartSection'>
						<div className='title'>Shopping Wish list ({wishList.length})</div>
						<div className='desc'>
							<span className='icon'><input type='radio' /></span>
							<span>Select all items</span>
						</div>
					</div>
					<div className='cartItemsSection'>
						<div className='desc lineCart'>
							<span className='icon'><input type='radio' /></span>
							<span className='title'>Outdoor Sport Dropship Store</span>
						</div>
						{wishList.map(product => <CartItem key={product.slug} product={product} wishListChanged={wishListChanged} />)}
					</div>
				</div>
				<div className='cart_summary'>
					<div className='summary'>
						<div className='title'>Summary</div>
						<div className='summary_det'>
							<p>subtotal</p>
							<p>XAF{total}</p>
						</div>
						<div className='summary_det'>
							<p>shipping fee</p>
							<p>XAF0.0</p>
						</div>
						<div className='summary_det'>
							<p>total</p>
							<p>XAF{total}</p>
						</div>
						<div onClick={() => navigate('/checkout')} draggable className='checkout_button'>checkout ({wishList.length})</div>
					</div>
					<div className='summary payment_methods'>
						<div className='title'>Payment Methods</div>
						<div className='payIcons'>
							<span className='icon'></span>
							<span className='icon'></span>
							<span className='icon'></span>
						</div>
						<div className='title'>Buyer Protection</div>
						<p>
							<span><BsShieldCheck /> </span>
							<span className='protect'>Get full refund if the item is not as described or if is not delivered</span>
						</p>
					</div>
				</div>
			</div>
            <Popup handleRemoveModal={removePopup} showPopup={wishListAdded} text={"Added to wish list"} />
            <Popup handleRemoveModal={removePopup} showPopup={wishListRemoved} text={"removed from wish list"} />
		</div>
	)
}

export default WishList
