import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import './chat.css'
import ChatMessages from './ChatMessages';
import Chatters from './Chatters';
import ChatDetails from './ChatDetails';
const Chat = () => {
        const [text, setText] = useState('');
        const [conversationWith, setConversationWith] = useState(null);

        const conversation = useRef('');
        
        
        const location = useLocation();
        let l = location.pathname.split('/')[2]


        
        if(conversationWith){
                const chat = conversation.current
                
        }
        useEffect(() => {
                setConversationWith(l)
        }, [location]);
        
        
        const [chatList, setChatList] = useState([
                {
                        name: "Pablo",
                        time: "07:09am",
                        last_message: "Hello man, whatsup?",
                        img: ""
                },
                {
                        name: "Pablo",
                        time: "07:09am",
                        last_message: "Hello man, whatsup?",
                        img: ""
                },
                {
                        name: "Pablo",
                        time: "07:09am",
                        last_message: "Hello man, whatsup?",
                        img: ""
                },

        ]);
        const [messages, setMessages] = useState([
                {
                        sender_id: 1,
                        message: "hello, whatsupp man!",
                        time: "09:32am"
                },
                {
                        sender_id: 2,
                        message: "hello, whatsupp man! hello, whatsupp man! estw hello, whatsupp man! hello, whatsupp man!",
                        time: "09:32am"
                },
                {
                        sender_id: 1,
                        message: "hello, whatsupp man!",
                        time: "09:32am"
                },
                {
                        sender_id: 1,
                        message: "hello, whatsupp man!",
                        time: "09:32am"
                },
                
        ]);
        
        const sendMessage = (e) => {
                e.preventDefault();
        }
        
  return (
        <div className='chat_app'>

                <div className={`chatSystem ${conversationWith ? 'conversationWithPerson' : ''}`}>
                        <div className='chatSystem__header'>
                                <span className='chat_state'>Customers</span>
                                <span className='chat_state'>Messages</span>
                        </div>
                        <div ref={conversation}  className='chat'>
                                <div className='chat_messages'>
                                        <Chatters chatList={chatList} />
                                </div>
                                <div className='chat_content'>
                                        {
                                                !conversationWith
                                                ?
                                                <div className='noConversation' />
                                                :
                                                <ChatDetails />

                                        }
                                </div>

                        </div>
                </div>
        </div>
  )
}

export default Chat
