import React, { useEffect, useLayoutEffect, useState } from 'react'
import MyBook from './MyBook'
import './MyBooks.css'
import book from './book.jpg'
import { getRequest } from '../../Resquest'
import { FiLoader } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsArrowLeft } from 'react-icons/bs'

const MyBooks = () => {
        const [books, setBooks] = useState([])
        const [isloading, setIsloading] = useState(false)
        const navigate = useNavigate()

        useLayoutEffect(() => {
                setIsloading(true)
                fetchMyBooks()
        }, [])
        
        const fetchMyBooks = async() => {
                const res = await getRequest('/customer/ebook/customer-ebook/')
                console.log("server reponse ", res);
                res.status === 200 && setBooks(res.data?.results)
                setIsloading(false)
        }
        const { t } = useTranslation()
  return (
    <div className='mybooks_page'>
        <main className='main_user_section'>
                <Link to='/'><BsArrowLeft />  { t("randoms.go_back") }</Link>
                <h2 className='title center paid_books_title'>{ t("baught_books.title") }</h2>
                <div className='user_main_grid'>
                        {
                        isloading
                        ?
                        <FiLoader />
                        :
                        books.length < 1
                        ?
                        (<div className='seller_with_no_book'>
                                <div className='title'>{ t("baught_books.alert_no_book") }</div>
                                <button onClick={() => navigate('/ebooks')}>{ t("baught_books.btn") }</button>
                        </div>)
                        :
                        books.map(book => (
                                <MyBook key={book?.transaction_id} book={book?.ebooks[0]} />
                        ))
                }
                <>
                </>
                </div>
        </main>
    </div>
  )
}

export default MyBooks