import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import 'react-lazy-load-image-component/src/effects/blur.css';

import One from '../../../Assets/img/about/1.jpeg';
import Two from '../../../Assets/img/about/2.jpeg';
import Three from '../../../Assets/img/about/3.jpeg';
import Four from '../../../Assets/img/about/4.jpeg';
import Five from '../../../Assets/img/about/5.jpeg';
import belvianeImage from '../../../Assets/img/about/belviane.jpeg';
import donaldImage from '../../../Assets/img/about/donald.jpeg';
import christianImage from '../../../Assets/img/about/christian.jpeg';

const originalImages = [
  { src: One, name: 'Belviane' },
  { src: Two, name: 'Donald' },
  { src: Three, name: 'Christian' },
  { src: Four, name: 'Belviane' },
  { src: Five, name: 'Donald' },
  { src: belvianeImage, name: 'Belviane' },
  { src: donaldImage, name: 'Donald' },
  { src: christianImage, name: 'Christian' },
];

const images = [...originalImages, ...originalImages, ...originalImages];

const SplashSection = () => {
  const [currentIndex, setCurrentIndex] = useState(originalImages.length);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex >= images.length - originalImages.length) {
          return originalImages.length;
        }
        return nextIndex;
      });
    }, 5000);

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getItemDimensions = () => {
    if (windowWidth < 768) return { width: 150, height: 200 };
    if (windowWidth < 1024) return { width: 180, height: 240 };
    return { width: 220, height: 300 };
  };

  const carouselItems = useMemo(() => 
    images.map(({ src, name }, index) => {
      const distance = index - currentIndex;
      const isCenter = distance === 0;
      return (
        <motion.div
          key={index}
          className={`carousel-item ${isCenter ? 'center' : ''}`}
          data-name={name}
          initial={false}
          animate={{
            scale: isCenter ? 1.1 : 0.8,
            rotateY: `${distance * 45}deg`,
            translateX: `${distance * 60}%`,
            opacity: Math.abs(distance) <= 2 ? 1 : 0,
            zIndex: isCenter ? images.length : images.length - Math.abs(distance)
          }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
          whileHover={{ scale: isCenter ? 1.15 : 0.85 }}
          onClick={() => setCurrentIndex(index)}
          style={getItemDimensions()}
        >
          <img
            src={src}
            alt={`Team member ${name}`}
            loading="lazy"
          />
        </motion.div>
      );
    }),
    [currentIndex, windowWidth]
  );

  return (
    <section className="splash-section">
      <div className="carousel">
        {carouselItems}
      </div>
      <div className="controls">
        {originalImages.map((_, index) => (
          <motion.button
            key={index}
            aria-label={`View image ${index + 1}`}
            className={`control-dot ${(currentIndex - originalImages.length) % originalImages.length === index ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index + originalImages.length)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') setCurrentIndex(index + originalImages.length);
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          />
        ))}
      </div>
    </section>
  );
};

export default SplashSection;