import React, { useContext, useLayoutEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router';

import { VscChromeClose } from 'react-icons/vsc'


const VerifySeller = ({ handleRequest, handleRemoveModal }) => {
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [showModal, setshowModal] = useState(false);
        
        
        
        const sendUser = (e) => {
                e.preventDefault()
                handleRequest()
        }
        const navigate = useNavigate()


        const requestRefresh = () => {
                
        }


  return (
    <div className='user_auth_popup'>
        <div onClick={handleRemoveModal} className='shadow_user' />
        <div className='auth_u'>
                <div className='loginModal_head'>
                        <p className='title'>Enter Login credential to access payment methods</p>
                </div>
                <div onClick={handleRemoveModal} className='closeModal'><VscChromeClose /></div>
                <form className='authForm' onSubmit={sendUser}>
                        <div className='inputContainer'>
                                <input  value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email Address' />
                        </div>
                        <div className='inputContainer'>
                                <input  value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Password' />
                        </div>
                        <div className='loginBtn'>
                                <input type='submit' value="Send Code" />
                        </div>
                </form>
        </div>
    </div>
  )
}

export default VerifySeller
