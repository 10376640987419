import React, {useRef, useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { getCourses } from './courses';
import { getSessions } from '../About/getSessions';

const Subscribe = ({id}) => {

  const [phone, setPhone] = useState('');
  const handlePhoneChange = (value) =>{
    setPhone(value);
  }
  const { t } = useTranslation();
  const sessions = getSessions(t);

// custom selected options for the session selection
const SessionDropdown = () => {
  const [selectedSession, setSelectedSession] = useState('');
  const [expandedSession, setExpandedSession] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const handleSessionChange = (e) => {
    const sessionId = e.target.value;
    setSelectedSession(sessionId);
    setExpandedSession(parseInt(sessionId));
    setSelectedPeriod('');
  };

  const handlePeriodChange = (periodId) => setSelectedPeriod(periodId);

  return (
    <div>
      <select value={selectedSession} onChange={handleSessionChange} className='session_dropdown'>
        <option value='' disabled>-- Select Session --</option>
        {sessions.map((session) => (
          <option key={session.id} value={session.id}>
            {session.name}
          </option>
        ))}
      </select>

      {expandedSession && (
        <div className="nested-period-dropdown">
          <label style={{ fontWeight: 500 }}>
            {t("subscribe_section.period_text")} {sessions.find((s) => s.id === parseInt(expandedSession)).name}
          </label>
          <select
            value={selectedPeriod}
            onChange={(e) => handlePeriodChange(e.target.value)}
            className='period_dropdown'
          >
            <option value='' disabled>-- {t("subscribe_section.placeholder.period")} --</option>
            {sessions
              .find((session) => session.id === parseInt(expandedSession))
              .periods.map((period) => (
                <option key={period.id} value={period.id}>
                  {period.name} - {period.price}
                </option>
              ))}
          </select>
        </div>
      )}
    </div>
  );
};


  
// Custom selected options for the course choice
  const CustomSelectedWithCheckBoxes = () => {
      const [isOpen, setIsOpen] = useState(false);
      const [selectedCourse, setSelectedCourse] = useState([]);
      const dropdownRef = useRef(null);
      const buttonRef = useRef(null);

      const options = getCourses(t).map(course => ({
        value: course.title,  // Using title for value
        label: course.title   // Using title for label
      }));

      const toggleDropdown = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      }

      const handleCheckBoxChange = (value) => {
        setSelectedCourse((prevSelected) =>
          prevSelected.includes(value)
            ? prevSelected.filter((item) => item !== value)  // Uncheck and remove from list
            : [...prevSelected, value]  // Check and add to list
        );
      };
      
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&  // Check if click is outside dropdown
          buttonRef.current && 
          !buttonRef.current.contains(event.target)  // Check if click is outside button
        ) {
          setIsOpen(false);
        }
      };
  
      React.useEffect(() => {
        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup event listener when component unmounts
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      return (
        <div className='custom-select-container' ref={dropdownRef}>
            <button onClick={toggleDropdown}
             ref={buttonRef}
             className={`custom-select-button ${isOpen ? 'focused' : ''}`}
             >
             <span className='selected-text'>
                {
                  selectedCourse.length ? selectedCourse.map(option => options.find(o => o.value === option)?.label).join(', ') :
                  t('subscribe_section.placeholder.choose_course')
                }
             </span>
            </button>
            {isOpen && (
              <div className='custom-select-dropdown'>
                 {options.map((option) => (
                  <label key={option.value} className='custom-select-option'>
                      <input
                       type='checkbox'
                       checked={selectedCourse.includes(option.value)}
                       onChange={() => handleCheckBoxChange(option.value)}
                      />
                        <span>{option.label}</span>
                  </label>
                 ))
                 }
              </div>
            )}
        </div>
      )
  }
  
 
  return (
    <div className='subscribe_section' id={id}>
      <div className='subscribe_titles'>
            <h1>{t('subscribe_section.main_title')}</h1>
            <h3>{t('subscribe_section.subtitle')}</h3>
            <form className='subscribe_form'>
               <div className='first_grid'>
                 <input type='text' placeholder={t('subscribe_section.placeholder.name')}/>
                 <input type='email' placeholder={t('subscribe_section.placeholder.email')}/>
               </div>
                <div className='first_grid'>
                      <CustomSelectedWithCheckBoxes />
                      <SessionDropdown />
                </div>
               <div className='first_grid'>
               <PhoneInput
                    country={'cm'}  
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder={t('subscribe_section.placeholder.telephone')}
                    inputStyle={{
                      position: 'relative',
                      width: '100%',
                      height: '50px',
                      paddingLeft: '60px',  // Ensure space for the flag dropdown inside the input
                      borderRadius: '5px',
                      border: '1px solid #BCB8B8',
                      boxSizing: 'border-box',
                      fontSize: '16px',
                      overflow: 'hidden',
                    }}
                    buttonStyle={{
                      position: 'absolute',  // Make sure the button is positioned inside the input
                      top: '15%',
                      left: '25px',  // Position the button on the left
                      transform: 'translateX(-50%)',  // Vertically center the button
                      border: 'none',
                      backgroundColor: 'transparent',
                    }}
                    containerClass="phone-input-container"
                    inputClass="phone-input-field"
                    buttonClass="phone-input-dropdown"
                  />
                   <textarea type='text' placeholder={t('subscribe_section.placeholder.expectations')}/>
               </div>
               <a href='/Formation e-hooyia.pdf' onClick={(e) => {
                  e.preventDefault();
                  const link = document.createElement('a');
                  link.href = '/Formation e-hooyia.pdf';
                  link.download = 'Formation e-hooyia.pdf';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}>
                  <button type='submit' className='submit-btn'>
                    {t('subscribe_section.btn')}
                  </button>
                </a>

            </form>
      </div>
    </div>
  )
  // 
}

export default Subscribe
