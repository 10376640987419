import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import facebook from './facebookLogo.png'
import { useTranslation } from 'react-i18next'

const FacebookButton = ({text}) => {
    const { t } = useTranslation()

    const responseFacebook = (res)=>{
      alert(t("oauth_invalid"))
    }
  return (
    <div onClick={responseFacebook}>
        <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
        autoLoad={false}
        fields="id,name,email,picture,first_name,last_name,gender,locale,timezone,verified"
        // callback={responseFacebook}
        
        render={renderProps => (
          <img
            onClick={renderProps.onClick} 
            src={facebook}
            alt="Sign in with facebook"
            className='oauthBtnImg facebookImg'
          />
        )}
    />
    </div>
  )
}

export default FacebookButton