import React from 'react'
import './Addons.css'

const Off = ({ discount }) => {
  return (
        <span className='price-discount'>{discount}% off</span>
  )
}

export default Off
