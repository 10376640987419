import React, { useContext, useEffect, useState } from 'react'
import './Cart.css'
import { BsShieldCheck } from 'react-icons/bs'
import CartItem from './CartItem'
import { useNavigate } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext'
import useLocalStorage from '../lib/UseLocalStorage'
import { useTranslation } from 'react-i18next'
import { postRequest } from '../../Resquest'

const Cart = () => {
	const [cart, setCart] = useContext(CartContext)
	const [user, setuser] = useLocalStorage('user')
    const [redirectUrl, setRedirectUrl] = useLocalStorage('redirectUrl')
	const [total, setTotal] = useState(0)
	const navigate = useNavigate()
	
	const removeProduct = (id) => {
		setCart(
			cart.filter(item => item.slug !== id)
		)
	}

	useEffect(() => {
		getTotal()
	}, [cart])
	

	const getTotal = () => {
		let total = 0;
		cart.map( item =>
			total += Number(item.price)
		)
		setTotal(total)
	}
	const checkoutUser = () => {
		if(!user){
			setRedirectUrl('/checkout')
			navigate('/login')
		}else {
			// submitCart()
			// window.location.href = '/checkout'
			navigate('/checkout')
		}
	}
	const submitCart = async() => {
		const data = {
			"products": []
		}
		const res = await postRequest('/cart-item/', data)
	}
	const { t } = useTranslation()
	return (
		<div className='container'>
			<div className='cart'>
				<div className='cart_list_section'>
					<div className='cartSection'>
						<div className='title'>{ t("cart_page.title") } ({cart.length})</div>
						<div className='desc'>
							<span className='icon'><input type='radio' /></span>
							<span>{ t("cart_page.select") }</span>
						</div>
					</div>
					<div className='cartItemsSection'>
						<div className='desc lineCart'>
							<span className='icon'><input type='radio' /></span>
							<span className='title'>{ t("cart_page.ebook") }</span>
						</div>
						{cart.map(product => <CartItem key={product.slug} product={product} handleRemove={removeProduct} />)}
					</div>
				</div>
				<div className='cart_summary'>
					<div className='summary'>
						<div className='title'>{ t("cart_page.summary") }</div>
						<div className='summary_det'>
							<p>{ t("cart_page.sub_total") }</p>
							<p>XAF {total}</p>
						</div>
						{/* <div className='summary_det'>
							<p>shipping fee</p>
							<p>XAF 0.0</p>
						</div> */}
						<div className='summary_det'>
							<p>{ t("cart_page.total") }</p>
							<p>XAF {total}</p>
						</div>
						<div onClick={checkoutUser} draggable className='checkout_button'>{ t("cart_page.checkout") } ({cart.length})</div>
					</div>
					<div className='summary payment_methods'>
						<div className='title'>méthodes de payement</div>
						<div className='payIcons'>
							<span className='icon'></span>
							<span className='icon'></span>
							<span className='icon'></span>
						</div>
						<div className='title'>Protection de l'acheteur</div>
						<p>
							<span><BsShieldCheck /> </span>
							<span className='protect'>Obtenez un remboursement complet si l'article n'est pas conforme à la description ou s'il n'est pas livré</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cart
