import React, { useRef } from 'react'

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EmailVerify = () => {
        const player = useRef('');
        
        const endAnimatiion = () => {
                player.current.setSeeker(120, false)
        }
        const { t } = useTranslation()
  return (
    <div className='email_verify'>
        <div className='container'>
                <div className='email_verify_loader'>
                        <Player
                                onEvent={event => {
                                        if (event === 'complete')  endAnimatiion() 
                                        
                                }}
                                autoplay
                                direction={120}
                                ref={player}
                                src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                                style={{ height: '150px', width: '150px' }}
                        >
                                {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                        </Player>
                </div>
                <p className='verify_email_text'>{ t("alerts.signup") }</p>
                <p className='continue_browsing'>
                        <Link to='/ebooks?auth=false'><BsArrowLeft />{ t("alerts.continue_browsing") }</Link>
                </p>
        </div>
    </div>
  )
}

export default EmailVerify
