import React, { createContext } from 'react'
import useLocalStorage from '../Components/lib/UseLocalStorage';

export const CartContext = createContext()

export const CartContextProvider= ({ children }) => {
    const [cart, setCart] = useLocalStorage('Cart', []);
    
    return (
        <CartContext.Provider value={[cart, setCart]}>
            {children}
        </CartContext.Provider>
    )
}