import React from 'react'
import { NavLink } from 'react-router-dom'

const Chatters = ({ chatList, user, handleConversation }) => {
  return (
    <div className='container'>
                {
                        chatList.map((chat, index) => (
                                <div onClick={() =>handleConversation(index)} key={index} className='person'>
                                        <div className='person_profile'>
                                        </div>
                                        <div className='personWrapper'>
                                                <div className='name_time'>
                                                        <p className='chat_person_name'>{chat.name}</p>
                                                        <p className='chat_person_time'>{chat.time}</p>
                                                </div>
                                                <p className='chat_last_message'>{chat.last_message}</p>
                                        </div>
                                </div>
                        ))
                }
        </div>
  )
}

export default Chatters
