import React from 'react'
import { BsStarFill } from 'react-icons/bs'

const Rating = ({ value }) => {
  const rating = new Array(value).fill(0)
  return (
    <div>
      {
                rating.map(rate => (
                        <span className='rating'><span className='icon'><BsStarFill /></span></span>
                ))
        }
    </div>
  )
}

export default Rating
