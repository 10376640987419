import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import './Product.css'
import './ProductDetails.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BsChevronDown, BsShield, BsShieldCheck, BsStarFill, BsAndroid2, BsFacebook, BsInstagram, BsMessenger, BsTwitter, BsWhatsapp } from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'

import Image from '../../Assets/img/41pOKvNMdnL._SX355_BO1,204,203,200_.jpg'
import Off from '../Addons/Off'
import Stars from '../Addons/Stars'
import Rating from '../Addons/Rating'
import { getRequest } from '../../Resquest'
import Popup from '../Addons/Popup'
import useLocalStorage from '../lib/UseLocalStorage'
import { CartContext } from '../../Context/CartContext'
import { WishListContext } from '../../Context/WishListContext'
import PopupAddToCart from '../Addons/PopupAddToCart'
import PopupAddToAlert from '../Addons/PopupAddToAlert'
import { useTranslation } from 'react-i18next'
import { HiChevronRight } from 'react-icons/hi2'
import { Loader } from '../Checkout/Checkout'
import ProductGrid from './ProductGrid'
import { setMetaDescription, tabTitle } from '../..'

const ProductDetails = ({ category_list }) => {
	const { id } = useParams()
	const [user, setuser] = useLocalStorage('user')
	const [ebook, setEbook] = useState({});
	const [reviews, setReviews] = useState([])
	const [cartAdded, setCartAdded] = useState(false)
	const [cartList, setCartList] = useContext(CartContext)
	const [wishList, setWishList] = useContext(WishListContext)
	const [wishListAdded, setWishListAdded] = useState(false)
	const [wishListRemoved, setWishListRemoved] = useState(false)
	const [loginAlert, setLoginAlert] = useState(false)
	const [loadingRecommendations, setLoadingRecommendations] = useState(true)
	const [recommendations, setRecommendations] = useState([])
	const [isloading, setIsloading] = useState(false)

	
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()


	useLayoutEffect(() => {
		requestEbook()
		.then( requestRating() )
		.then( fetchRecommendations() ) 
		
	}, []);

	tabTitle(`${ebook.name} - HooYia book`)
	useLayoutEffect(() => {
		fetchRecommendations()
	}, [ebook]);
	
	
	const fetchRecommendations = async() => {
		const response = await getRequest(`/public/ebook/display/?categories=${ebook?.category}&limit=4`)
		response?.status === 200 && filter_book_in_recomm(response?.data)
		setLoadingRecommendations(false)
	}


	/**
	 * Filter the recommendations to make sure selected book is not in list
	 */
	const filter_book_in_recomm = (response) => {
		let results = response?.results
		let book_id = location.pathname.split('ebooks/')[1]
		if(results){
			results = results.filter(book => book?.slug !== book_id)
		}
		setRecommendations(results)
	}

	const requestEbook = async () => {
		setIsloading(true)
		const response = await getRequest(`/public/ebook/display/${id}/`)
		response.statusText === 'OK'
			? saveData(response.data)
			: console.warn(response)
	}

	const saveData = (data) => {
		setEbook(data)
		setIsloading(false)
	}

	const requestRating = async () => {
		const response = await getRequest(`/public/ebook/review/?slug=${id}/`)
		console.log("reviews for book", response.data);
		response.statusText === 'OK'
			? setReviews(response.data.results)
			: console.warn(response)
	}


	const removePopup = () => {
		setWishListAdded(false)
		setCartAdded(false)
		setWishListRemoved(false)
	}


	const removeAlertBox = () => {
		setLoginAlert(false)
	}


	const animateCartAdded = () => {
		setCartAdded(true)
		setTimeout(() => {
			setCartAdded(false)
		}, 5000)
	}

	
	const animateWishListRemoved = () => {
		setWishListRemoved(true)
		setTimeout(() => {
			setWishListRemoved(false)
		}, 3000)
	}


	const animateWishListAdded = () => {
		setWishListAdded(true)
		setTimeout(() => {
			setWishListAdded(false)
		}, 3000);
	}


	const wishListChanged = () => {
		if(!user){
			setLoginAlert(true)
		}else{

			if (inList) {
				removeFromList()
				animateWishListRemoved()
			} else {
				addToList()
				animateWishListAdded()
			} 
		}
	}


	const inCart = cartList && cartList.filter(book => book.slug === id).length > 0 ? true : false
	const inList = wishList && wishList.filter(book => book.slug === id).length > 0 ? true : false


	const addToCart = () => {
		!inCart && setCartList(cartList => [...cartList, ebook])
		animateCartAdded()
	}


	const removeFromList = () => {
		setWishList(
			wishList.filter(item => item.slug !== id)
		)
	}


	const addToList = () => {
		setWishList(
			[...wishList, ebook]
		)
	}


	const buyBook = () => {
		if(!user?.user){
			setLoginAlert(true)
		}else {
			!inCart && setCartList(cartList => [...cartList, ebook])
			navigate('/cart')
		}
	}

	useEffect(() => {
		const bookDescription = `${ebook?.description} - HooYia`
		const ogBookDescription = `${ebook?.description} - HooYia`
		
		setMetaDescription(bookDescription, ogBookDescription);
		
		return () => {
		  setMetaDescription('', '');
		};
	}, []);


	return (
		<div className='product_details_page container'>
			<div className='product_detail_top_grid'>
				<div className='images'>
					{
						isloading ?
						<div className='imgMain loading_skeleton' />
						:
						(
						<>
							<div className='imgMain'>
								{ebook?.cover && <img src={ebook?.cover} alt="product name" />}
							</div>
							<div className='subImages'>
							</div>
						</>
						)
					}
				</div>
				<div className='content'>
					<h2 className='product_the_title'>{ ebook.name }</h2>
					<div className='description'>{ebook?.description}</div>
					<div className='ratings' draggable={false}>
						<Stars value={ebook?.avg_rating} />
						<span className='rating_value'>{ebook?.avg_rating}</span>
						<span className='iconB'><BsChevronDown /></span>
					</div>
					<div className='price_dis'>
						<p>
							<span className='price priceB'>XAF {ebook.price}</span>
							<span className='price priceOld'>XAF {ebook.discount_percentage > 0 ? parseInt(ebook.price) + (ebook.discount_percentage/100) * ebook.price:ebook.price}</span>
						</p>
						<p><Off discount={ebook.discount_percentage} /> </p>
					</div>
					<div className='buttons'>
						<button onClick={ buyBook } className='buyBtn'>{ t("product_detail.buy") }</button>
						<button onClick={addToCart} className='buyBtn addCartbtn'>{ t("product_detail.cart") }</button>
						<button onClick={wishListChanged} className='wishlistBtn buyBtn'>{ t("product_detail.wish_list") }</button>
					</div>
					<div className='shipping_talk'>
						<div className='iconB2'>
							<BsShieldCheck />
						</div>
						<div className='shipping_desc'>
							<p>{ t("product_detail.buy_prot") }</p>
							<span>{ t("product_detail.buy_prot_desc") }</span>
						</div>
					</div>
					<div className='share_product_links'>
						<div className='text'>{ t("product_detail.share") }</div>
						<div className='icon'>
							<span className='icon'> <a href="http://www.facebook.com/"> <BsFacebook /> </a></span>
							<span className='icon'> <a href="http://www.twitter.com/"><BsTwitter /> </a></span>
							<span className='icon'> <a href="http://www.instagram.com/"><BsInstagram /> </a></span>
							<span className='icon'> <a href="http://www.messager.com/"><BsMessenger /> </a></span>
							<span className='icon'> <a href="http://www.wa.me/?message='I will like to share with you this incredible item '/"><BsWhatsapp /> </a></span>
						</div>
					</div>

				</div>
				<div className='side_comments'>

				</div>
			</div>
			<div  onClick={() => console.log('navigating...')}  className='recommendation_and_conclusion'>
				<div className='pined_category'>
					<div className='title'>{ t("product_detail.category") }</div>
					<ul className='category_list'>
						{
							category_list?.map(cat => (
								<li key={ cat.pk } className='category_item'>
									<a onClick={()=> navigate('/ebooks?category='+ cat?.name, {state: {"dir": cat?.name}})} >{ cat?.name }{ cat?.children.length > 1 && <span className='cat_dropdown_indicator'><span><HiChevronRight /></span></span> }</a>
									{
										cat?.children.length > 1 && (
											<div className='drop_cat_list'>
												{
												cat?.children?.map(child_cat => (
													<li key={child_cat } className='category_item'>
														<a onClick={()=> navigate(`/ebooks?category=${cat?.name}-${child_cat}`, {state: {"dir": cat?.name}})} >{ child_cat }</a>
													</li>
												))
												}
											</div>
										)

									}
								</li>
							))
						}
					</ul>
				</div>
				<div className='desc_right'>

					<div className='productGridItem'>
						<div className='detail-review'>
							<a href='#product-details' className='productReviewLink'>{ t("product_detail.prod_desc_title") }</a>
							<a href='#product-review' className='productReviewLink'>{ t("product_detail.prod_review_title") }(2)</a>
						</div>
						<section className="itemDescription" id='product-details'>
							<h4 className="title">{ t("product_detail.prod_desc_title") }</h4>
							<p>{ebook.description} </p>
							<ul className='productDetailList'>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.author") }</p>
									<p className='value'>{ebook.author}</p>
								</li>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.category") }</p>
									<p className='value'>
										<span className='top_selling_tag commic_tag'>{ebook?.category}</span>
									</p>
								</li>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.price") }</p>
									<p className='value'>{ebook?.price}</p>
								</li>
								<li className='productDetailListItem'>
									<p className='key'>{ t("product_detail.type") }</p>
									<p className='value'>{ t("product_detail.ebook") }</p>
								</li>
							</ul>
						</section>

						<Rating />
						{/* ============ REVIEWS SECTION========== */}
						<section className="itemDescription reviews" id='product-review'>
							<h4 className="title">{ t("product_detail.reviews") }</h4>

							{
								reviews?.map(rev => (
									<div key={rev} className='review'>
										<div className='profile'>
											<div className='profilePic'>
											</div>
										</div>
										<div className='review-content'>
											<Stars value={5} />
											<p>{rev}</p>
										</div>
									</div>
								))
							}

						</section>

						{/* ============ RECOMMENDATION ============ */}
					</div>
					<section className='recommendation' id='recommendation'>
						<h4 className="title">{ t("product_detail.recommandations") }</h4>
						<span>({ t("product_detail.recommandation_desc") }) </span>
						<div className='recommendation_items'>
							<div className='loadingrecommendations'>
							{
								loadingRecommendations ?
								(
										<Loader />
										)
										:
										<div className='recommendation_overflow'>
											<ProductGrid products={recommendations} />
										</div>
									}
							</div>
						</div>
					</section>
				</div>
			</div>
		<PopupAddToCart handleRemoveModal={removePopup} showPopup={cartAdded} cart={true} text={ t("modals.add_to_cart.text") } />
		<PopupAddToAlert book_id={ ebook?.slug } handleRemoveModal={removeAlertBox} showPopup={loginAlert} />
		<Popup handleRemoveModal={removePopup} showPopup={wishListAdded} text={ t("modals.add_to_wishlist.text") } />
		<Popup handleRemoveModal={removePopup} showPopup={wishListRemoved} text={ t("modals.rm_to_wishlist.texts") } />
	</div>
	)
}

export default ProductDetails
