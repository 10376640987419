import React from 'react'
import { useTranslation } from 'react-i18next'

const SalesTable = ({ sales }) => {
    const { t } = useTranslation()

  return (
    <table>
        <thead>
            <tr>
                <th>{ t("dashboard_earning.customer") }</th>
                <th>{ t("dashboard_earning.transactions_table.transaction_id") }</th>
                <th>{ t("dashboard_earning.counter") }</th>
                <th>{ t("dashboard_earning.book") }</th>
                <th>{ t("dashboard_earning.transactions_table.pay_ammount") }</th>
                <th>{ t("dashboard_earning.transactions_table.pay_date") }</th>
                <th>{ t("dashboard_earning.transactions_table.activity") }</th>
            </tr>
        </thead>
        {
            sales.length === 0
            ?
            <h3 className='no_earnings'>{ t("nothing_sold") }</h3>
            :
            <tbody>
                {
                    
                    sales?.map(sale => (
                        <tr>
                            <td>{ sale.customer }</td>
                            <td>{ sale.transaction_id }</td>
                            <td>{ sale?.list_detail_order.length }</td>
                            <td>{ sale?.list_detail_order[0]?.book.name}</td>
                            <td>-</td>
                            <td>{ sale.transaction_date }</td>
                            <td>{
                                sales.status
                                ? <span className='earning_activity spend'>
                                    { t("dashboard_earning.expenses") }</span>
                                : <span className='earning_activity earn'>
                                    { t("dashboard_earning.earn") }</span>
                            }</td>
                        </tr>
                    ))
                }
                
            </tbody>
        }
    </table>
  )
}

export default SalesTable