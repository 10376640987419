import React from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import useLocalStorage from '../lib/UseLocalStorage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FlutterWaveButton({amount, transaction_id}) {
  const [user, setUser] = useLocalStorage('user')

  const config = {
    public_key: "FLWPUBK-38d1cbae1cb20293a6a70c0326721dc1-X",
    tx_ref: transaction_id,
    amount: amount,
    currency: 'XAF',
    payment_options: 'card,mobilemoney,usd',
    redirect_url: '/payment-status',
    customer: {
      email: user?.user?.email || 'contact@e-hooyia.com',
       phone_number: '**********',
      name: user?.user?.first_name + " " + user?.user?.last_name,
    },
    customizations: {
      title: 'E-HooYia',
      description: 'Payment payment',
      logo: 'https://user-images.githubusercontent.com/81464575/239638666-a3f81db4-a300-4f7d-b411-b8839cc40040.png',
    },
  };

  const handleFlutterPayment = useFlutterwave(config)
 
  const { t } = useTranslation()
  return (
    <div className="App">

      <button
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
                closePaymentModal() 
            },
            onClose: () => {},

          });
        }}
      >
        { t("cart_page.pay_with_flutter") }
      </button>
    </div>
  );
}