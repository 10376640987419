
import { GoArrowRight } from "react-icons/go";

import { ReactComponent as Loader } from '../../../Assets/Loaders/loader.svg'
import { useTranslation } from "react-i18next";

export const OnboardActions = ({save_data, handle_go_back, isloading, index, finish = false}) => {

	const { t } = useTranslation()
	return (
	  
	  <div className='next_btn_wrapper'>
		<button className={`onboard_next_btn seller_onboard_back_btn`} onClick={handle_go_back} >
		  <span>{t("back_btn")}</span>
		</button>
  
		{
		  finish ? (
			<button disabled={isloading} className={`onboard_next_btn ${isloading ? "onboard_load_btn" : ""}`} onClick={save_data}>
			  <span>{t("finish_btn")}</span>
			  <span className='btn_svg'>
				{ isloading ? <Loader /> : <GoArrowRight /> }
			  </span> 
			</button>
		  ) : (
		  <button disabled={isloading} className={`onboard_next_btn ${isloading ? "onboard_load_btn" : ""}`} onClick={save_data}>
			<span>{t("next_btn")}</span>
			<span className='btn_svg'>
			  { isloading ? <Loader /> : <GoArrowRight /> }
			</span> 
		  </button>
		  )
		}
	  </div>
	)
  }