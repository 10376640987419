import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'

import 'react-image-crop/dist/ReactCrop.css';


import './CreateSellerAccount.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllBanks, getCountry, postRequest } from '../../Resquest';
import useLocalStorage from '../lib/UseLocalStorage';
import { useTranslation } from 'react-i18next';
import SlidePercent from './Onboard/SlidePercent';
import PersonalInfo from './Onboard/PersonalInfo';
import OnboardProfessionalInfo from './Onboard/OnboardProfessionalInfo';
import OnboardBankInfo from './Onboard/OnboardBankInfo';
import { IsSellercontext } from '../../Context/IsSellerContext';
import axios from 'axios';
const CreateSellerAccount = () => {
	const [user, setUser] = useLocalStorage('user')
	
	const [selectedCountry, setSelectedCountry] = useState({})
	const [countryList, setCountryList] = useState([])
	const [bankList, setBankList] = useState([])

	const navigate = useNavigate()
	const location = useLocation()
	const searches = location.search
	const params = new URLSearchParams(searches);
	const step = params.get("step");
	const [percent, setPercent] = useState(0)
	const [sellerOnboardRequest, setSellerOnboardRequest] = useLocalStorage("seller_onboard_request", {})
	const steps_to_go = [ "personal_detail", "professional_detail", "financial_infomation", "extra"]
	const [isSellerAccount, setIsSellerAccount] = useContext(IsSellercontext);

	useLayoutEffect(() => { 
		let step_count = steps_to_go.indexOf(step)
		setPercent((step_count + 1) * 100 / (steps_to_go.length - 1))
	}, [step])
	
	
	useLayoutEffect(() => { 
		setTimeout(() => {
			setPercent(33)
		}, 500);
	  }, [])
	
	
	useEffect(() => {
		fetchCountry()
		getBanks()
	}, [])
	

	/**
	 * Request list of countries from the backend
	 */
	const fetchCountry = async() => {
		const response = await getCountry();
		const res = response.data?.results
		setCountryList(res)
	}

	
	/**
	 * Request bank information from the backend
	 */
	const getBanks = async() => {
		const response = await getAllBanks();
		response.status === 200 && setBankList(response.data.results)
	}

	const sendUser = (e) => {
		e.preventDefault()
	}


	const alertModal = (message) => {
		
	}


	/**
	 * Make request to update user profile to seller account using info from user form
	 * @param {Object} userData Containing the user request object
	 */
	const createSellerAccount = async (userData) => {
		!selectedCountry?.pk && alertModal('invalid country')

		const resp = await postRequest('/auth/seller-profile/', userData)

		console.log("creating seller profile", resp);
		if(resp?.status === 201){
			// updateProfilePic(resp.data);
			navigate("/seller_account_success")
		}
		else if(resp?.response?.data[0] === 'This profile already exist!!'){
			switchToSelling()
		}
		
	}

	

	const updateProfilePic = async(sellerProfile) => {
		const formData = new FormData();
		formData.append("pp", sellerOnboardRequest.pp)

		const resp = await axios.patch(`https://hooyia.herokuapp.com/api/v1/auth/seller-profile/${sellerProfile?.pk}/`, formData, {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		})
		console.log("image saving response", resp);
		resp?.data && navigate("/seller_account_success")
	}


	const switchToSelling = () => {
		navigate('/', {state: true})
		setIsSellerAccount(true) 
	}
	

	/**
	 * Collect and save the user data that will be used to create user
	 * @param {String} current_step the position of the user in the onboard process
	 * @param {Object} data the data being created from that step
	 */
	const save_data_request_data = (current_step, data) => {
		setSellerOnboardRequest(seller_onboard_request => ({...seller_onboard_request, ...data}))
		navigate('?step='+steps_to_go[current_step + 1]);
	}


	const go_to_previous = ( current_step ) => {
		navigate('?step='+steps_to_go[current_step - 1]);
	}


	/**
	 * Handle form submit when user clicks finish
	 * @param {Object} data User object from form
	 */
	const submit_request = async(data) => {
		let request = {...sellerOnboardRequest, ...data}
		delete request.pp

		setSellerOnboardRequest(seller_onboard_request => ({...seller_onboard_request, ...data}))
		createSellerAccount(request)
				
	}

	const { t } = useTranslation()
	return (
		<div className='create_seller_account'>
			<div className='container'>
				<div className='seller_account_head seller_account_main_head'>
					<h2>{ t("start_selling.title") }</h2>
					<p>{ t("start_selling.title_desc_one") }</p>
					<p>{ t("start_selling.title_desc_two") }</p>
					<SlidePercent percent = { percent }  />
				</div>
				<div className='create_seller_form'>
					<form className='authFormSellerAccount' onSubmit={sendUser}>
						<>
						{
							step === 'personal_detail' ?
							<PersonalInfo user={user} countryListServer={countryList} handle_save_request={save_data_request_data} handle_go_back={go_to_previous} index={0}  />
							: step === 'professional_detail' ?
							<OnboardProfessionalInfo handle_save_request={save_data_request_data} handle_go_back={go_to_previous} index={1}  />
							: step === 'financial_infomation' ?
							<OnboardBankInfo  handle_form_submit={submit_request} serverBanks={bankList} handle_go_back={go_to_previous} index={2}  />
							:
							<PersonalInfo user={user} countryListServer={countryList} handle_save_request={save_data_request_data} handle_go_back={go_to_previous} index={0}  />
						}
						</>

					</form>
					<aside className='create_account_side'>
						<div className='create_seller_player'>
						{ t("landing.welcome") }
						</div>
						<div className='create_seller_aside'>
							<div className='title'>{ t("seller_onboarding.aside.title") }</div>
							<p>{ t("seller_onboarding.aside.content") } </p>
						</div>

					</aside>
				</div>
			</div>
		</div>
	)
}

export default CreateSellerAccount


