import React from 'react'

const SellerProfileEdit = () => {
  return (
    <div>
            editing seller profile
            
    </div>
  )
}

export default SellerProfileEdit
