import React from 'react'
import './Footer.css'
import { IoCarOutline, IoCardOutline, IoCashOutline, IoPersonOutline, IoShieldCheckmarkOutline } from 'react-icons/io5'
import { BsAndroid2, BsApple, BsFacebook, BsInstagram, BsMessenger, BsTwitter, BsWhatsapp } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import FooterU from './FooterU'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const {t} = useTranslation()
  return (
    <div className='Footer'>
      <section className='footer_upper'>
        <div className='container footer_top_grid'>


          <div className='footer_grid_item_value'>
            <div className='icon'> <IoShieldCheckmarkOutline /> </div>
            <div className='desc'>
              <div className='title'>{ t("footer_user.one.g_val") }</div>
              <div className='text'>{ t("footer_user.one.g_val_desc") }</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoCarOutline /> </div>
            <div className='desc'>
              <div className='title'>{ t("footer_user.one.car") }</div>
              <div className='text'>{ t("footer_user.one.car_desc") }</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoCardOutline /> </div>
            <div className='desc'>
              <div className='title'>{ t("footer_user.one.card") }</div>
              <div className='text'>{ t("footer_user.one.card_desc") }</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoShieldCheckmarkOutline /> </div>
            <div className='desc'>
              <div className='title'>{ t("footer_user.one.secure") }</div>
              <div className='text'>{ t("footer_user.one.secure_desc") }</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoPersonOutline /> </div>
            <div className='desc'>
              <div className='title'>{ t("footer_user.one.help") }</div>
              <div className='text'>{ t("footer_user.one.help_desc") }</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'>
              <span className='icon'><BsAndroid2 />  </span>
              <span className='icon'><BsApple /> </span>
            </div>
            <div className='desc'>
              <div className='title'>{ t("footer_user.one.s_better") }</div>
              <div className='text'>{ t("footer_user.one.s_better_desc") }</div>
            </div>
          </div>

        </div>
        <div className='container footer_top_grid footer_bottom_grid'>
          <div className='footer_grid_item_value'>
              <div className='title'>{ t("footer_user.two.s_connected") }</div>
            <div className='icon'>
              <span className='icon'> <BsFacebook /> </span>
              <span className='icon'> <BsTwitter /> </span>
              <span className='icon'> <BsInstagram /> </span>
              <span className='icon'> <BsMessenger /> </span>
              <span className='icon'> <BsWhatsapp /> </span>
            </div>
          </div>

          <div className='footer_grid_item_value'>
          </div>

          <div className='footer_grid_item_value'>
              <div className='title'>{ t("footer_user.two.swu") }</div>
            <div className='desc'>
              <div className='text'>
                <p><Link to='/cart/checkout'>{ t("footer_user.two.swu_desc.one") }</Link></p>
                <p><Link to='/delivery-options'>{ t("footer_user.two.swu_desc.two") }</Link></p>
                <p><Link to='/buyer-protection'>{ t("footer_user.two.swu_desc.three") }</Link></p>
                <p><Link to="/refund-policy"><div className="descText">{ t("footer.refund_policy") }  </div></Link></p>
                <p><Link to="/privacy-policy"><div className="descText">{ t("footer.privacy_policy") }  </div></Link></p>
                
              </div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
              <div className='title'>{ t("footer_user.two.cust_service") }</div>
            <div className='desc'>
              <div className='text'>
                <p><Link to='/terms-and-conditions'>Terms and conditions</Link></p>
                <p><Link to='/cart/checkout'>{ t("footer_user.two.cust_service_desc.one") }</Link></p>
                <p><Link to='/delivery-options'>{ t("footer_user.two.cust_service_desc.two") }</Link></p>
                <p><Link to='/buyer-protection'>{ t("footer_user.two.cust_service_desc.three") }</Link></p>
                <p><Link to='/buyer-protection'>{ t("footer_user.two.cust_service_desc.four") }</Link></p>
                
              </div>
            </div>
          </div>


          <div className='footer_grid_item_value'>
              <div className='title'>{ t("footer_user.two.cwu.four") }</div>
            <div className='desc'>
              <div className='text'>
                <p><Link to='/cart/checkout'>{ t("footer_user.two.cwu_desc.one") }</Link></p>
                <p><Link to='/delivery-options'>{ t("footer_user.two.cwu_desc.two") }</Link></p>
                <p><Link to='/buyer-protection'>{ t("footer_user.two.cwu_desc.three") }</Link></p>                
              </div>
            </div>
          </div>

        </div>
      </section>

      <section>
        <div className='container footer_lower_grid'>
          <div className='buttom_desc'>
            <div className='indi'>
              <div className='title'>{ t("footer_user.three.help") }</div>
              <div className='desc'>{ t("footer_user.three.help_desc") }</div>
            </div>
            <div className='indi'>
              <div className='title'>{ t("footer_user.three.bbc") }</div>
              <div className='desc'>{ t("footer_user.three.bbc_desc") }</div>
            </div>
            
          </div>
          <div className='buttom_desc'>
            <div className='indi'>
              <div className='title'>{ t("footer_user.three.hmls") }</div>
              <div className='desc'>{ t("footer_user.three.hmls_desc") }</div>
            </div>
            <div className='indi'>
              <div className='title'>{ t("footer_user.three.sg") }</div>
              <div className='desc'>{ t("footer_user.three.sg_desc") }</div>
            </div>

          </div>

        </div>
      </section>
      <section>
        <FooterU />
      </section>
    </div>
  )
}

export default Footer
