import { useEffect, useRef } from "react";

const SlidePercent = ({ percent }) => {
    const slider = useRef('');
  
    useEffect(() => {
      if (slider.current) {
        slider.current.style.setProperty('--after-width', `${percent}%`);
      }
    }, [percent])
    
    
    return (
      <div className='onboard_slider' ref={slider} />
    )
  }
  
  export default SlidePercent;
  