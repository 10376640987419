
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import useLocalStorage from '../lib/UseLocalStorage'

const variants = {
    hidden: {
        y: "-100vh",
        opacity: 0,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    visible: {
        y: "-50vh",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    exit: {
        y: "-100vh",
        opacity: 0,

    }
}


const PopupAddToAlert = ({ book_id, showPopup, handleRemoveModal, message}) => {
    const { t } = useTranslation()
    const [redirectUrl, setRedirectUrl] = useLocalStorage('redirectUrl')

    const navigate = useNavigate()
    const go_to_login = () => {
        setRedirectUrl('/ebooks/' + book_id)
        navigate('/login')
    }

    return (
        <AnimatePresence
            initial={false}
            mode='wait'
        >
            {
                showPopup && (
                    <div className='book_modalWrapper'>
                        <motion.div
                            className='book_notify_modal no_user_close'
                            variants={variants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                                <div className='add_to_cart_modal_inner'>
                                        <p>{message}</p>
                                        <p className='modal_notify_text' >{ t("alerts.login_alert_one") } <br />{ t("alerts.login_alert_two") }</p>
                                        <span onClick={go_to_login} className='redirect_to_login_url' to="/login">{t("alerts.login_link")}</span>
                                        <button className='close_button' onClick={handleRemoveModal}>{t("close_btn")}</button>
                                </div>
                                <span className='no_user_close_btn' onClick={()=>handleRemoveModal()}><VscChromeClose /></span>
                        </motion.div>
                    </div>
                )
            }
        </AnimatePresence>
    )
}

export default PopupAddToAlert
