import React from 'react'
import { BsStarFill } from 'react-icons/bs'

const Stars = ({ value }) => {
        const stars = Array(5).fill(0)
  return (
    <>
        {
        stars.map((_, index) => (
                <span key={index} className='icon'>
                        <BsStarFill />
                </span>
        ))
        }
    </>
  )
}

export default Stars
