import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const mapContainerStyle = {
  width: '100%',
  height: '600px'
};
// 5.470943, 10.432859

const center = {
  lat: 5.470943,
  lng: 10.432859 // Coordinates for Hôtel Le Carrefour Le Maire
};

const CustomMap = () => {
  return (
    <MapContainer 
      center={[center.lat, center.lng]} 
      zoom={15} 
      style={mapContainerStyle}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[center.lat, center.lng]}>
        <Popup>
          2eme Careffour Eveche
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default CustomMap;