import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import translationEN from './Assets/locales/en/translationEN.json'
import translationFR from './Assets/locales/fr/translationFR.json'
import { UserContextProvider } from '../src/Context/AuthContext';
import { IsSellerContextProvider } from '../src/Context/IsSellerContext';
import { CartContextProvider } from '../src/Context/CartContext';
import { WishListContextProvider } from '../src/Context/WishListContext';
import { SellerReloadContextProvider } from './Context/SellerReload';
import ScrollToTop from './Components/Addons/ScrollToTop';

export const tabTitle = (title) => {
  return (document.title = title)
}

export const setMetaDescription = (description, ogDescription) => {
  // Regular meta description
  const metaTag = document.querySelector('meta[name="description"]');
  if (metaTag) {
    metaTag.setAttribute('content', description);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute('name', 'description');
    newMetaTag.setAttribute('content', description);
    document.head.appendChild(newMetaTag);
  }

  // Open Graph description
  const ogTag = document.querySelector('meta[property="og:description"]');
  if (ogTag) {
    ogTag.setAttribute('content', ogDescription);
  } else {
    const newOgTag = document.createElement('meta');
    newOgTag.setAttribute('property', 'og:description');
    newOgTag.setAttribute('content', ogDescription);
    document.head.appendChild(newOgTag);
  }
};


const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
};


i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr'],
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    resources,
    fallbackLng: "fr",
    debug: true,
    // backend: {
    //   loadPath: '/assets/locales/{{lng}}/translation.json',
    // },
    react: { useSuspense: false },
  });
  window.scrollTo({ top: 0, behavior: 'smooth' });

const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<hAppWrapper />, rootElement);
} else {
  render(<AppWrapper />, rootElement);
}

function AppWrapper(){
  return (
    <GoogleOAuthProvider clientId="426100214455-v4e5qnbvmu74t24uh9tel0n6qklskv6q.apps.googleusercontent.com">
      <SellerReloadContextProvider>
        <IsSellerContextProvider>
          <UserContextProvider>
            <CartContextProvider>
              <WishListContextProvider>
                <Router>
                  <ScrollToTop />
                  <App />
                </Router>
              </WishListContextProvider>
            </CartContextProvider>
          </UserContextProvider>
        </IsSellerContextProvider>
      </SellerReloadContextProvider>
    </GoogleOAuthProvider>
  )
}

// root.render(
//   <React.StrictMode>
//   </React.StrictMode>
// );



reportWebVitals();
