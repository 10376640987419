import React, { useLayoutEffect, useState } from 'react'
import './BookDetails.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaFacebook, FaLink, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { AnimatePresence } from 'framer-motion'
import { CopyAlert } from '../Dashboard/Components/ListBooks'
import { deleteRequest, getRequest } from '../../../Resquest'

const BookDetails = ({ user, books }) => {
    const { id } = useParams()
    const [isloading, setIsloading] = useState(false)
    const [ebook, setEbook] = useState(null)
    const [copiedBook, setCopiedBook] = useState(false)
    
    const link = window.location.origin + '/ebooks/' + id

    const navigate = useNavigate()

    useLayoutEffect(() => {
        requestEbook()
    }, [])

    const requestEbook = () => {
		setIsloading(true)
        let search_book = books.filter(book => book.slug === id )
        saveData(search_book[0])
	}

	const saveData = (data) => {
        if(data){
            setEbook(data)
            setIsloading(false)
        }
        request_book_from_server()
	}

    const request_book_from_server = async() => {
        const response = await getRequest(`/seller/ebook/owner-ebook/${id}/`)
        response.status === 200 && setEbook(response.data)
    }

    const copyLink = () => {
		navigator.clipboard.writeText(link)
		setCopiedBook(true)
		setTimeout(() => {
			setCopiedBook(false)
		}, 3000);
	}

    const deleteBook = async () => {
		const response = await deleteRequest(`/seller/ebook/owner-ebook/${id}/`)
		response.status === 204 ?
			window.location.href = '/'
			: console.warn(response)
	}

    const editBook = () => {
		navigate(`/${user?.user?.first_name}/book?edit=true`,
			{state: {...ebook}}
		)
	}

    const { t } = useTranslation();

  return (
    <div className='container book_details'>
        <div className='book_card_head_flex'>
            <div className='book_card left'>
                <div className='book_card_img_wrapper'>
                    <img src={ebook?.cover} alt={ ebook?.name } />
                </div>
            </div>
            <div className='book_detail_right'>
                <div className='book_card'>
                    <div className='book_card_desc_wrapper'>
                        <div className='book_text'>
                            <p className='key'>{ t("book_det.name") }</p>
                            <p className='value'>{ ebook?.name }</p>
                        </div>
                        <div className='book_text'>
                            <p className='key'>{ t("product_detail.author")}</p>
                            <p className='value'>{ ebook?.author }</p>
                        </div>
                        <div className='book_text'>
                            <p className='key'>{t("product_detail.price")}</p>
                            <p className='value'>{ ebook?.price }</p>
                        </div>
                        <div className='book_text'>
                            <p className='key'>{t("product_detail.price")}</p>
                            <p className='value'>{ ebook?.avg_rating }</p>
                        </div>
                        <div className='book_text'>
                            <p className='key'>{t("ebooks.sold")}</p>
                            <p className='value'>0</p>
                        </div>
                        <div className='book_text'>
                            <p className='key'>{ t("non_user_nav.language") }</p>
                            <p className='value'>{ ebook?.language === 'F' ? "Fracais": "English"}</p>
                        </div>
                        <div className='book_text'>
                            <p className='key'>Status</p>
                            <p className='value'>
                                <span>{ ebook?.in_review_mode ? "Pending approval": "Live" }</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='ebook_specific_share_urls'>
                <ul>
                    <li>
                        <span className='icon fb'><FaFacebook /></span>
                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + link } target="_blank">{ t("share.fb") }</a>
                    </li>
                    <li>
                        <span className='icon linIn'><FaLinkedin /></span>
                        <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + link } target="_blank">{ t("share.linkedin") }</a>
                    </li>
                    <li>
                        <span className='icon twitter'><FaTwitter /></span>
                        <a href={"https://twitter.com/intent/tweet?url=YOUR_URL_HERE&text=" + link } target="_blank">{ t("share.tweeter") }</a>
                    </li>
                    <li onClick={copyLink} className='copy_store'>
                        <span className='icon copylink'><FaLink /></span>
                        <span>{ t("share.copy") }</span>
                    </li>
                </ul>
                </div>
            </div>
        </div>
        <div className='book_details_description'>
            <h4 className='bold'>{t("dashboard_edit_form.desc")}</h4>
            <div className='desc_text'>{ ebook?.description }</div>
            <div className='other_btns'>
                <button onClick={editBook}>{ t("book_det.edit") }</button>
                <button className='delete_btn' onClick={deleteBook}>{ t("book_det.delete") }</button>
            </div>
        </div>

        <AnimatePresence
            initial={false}
            mode='wait'
        >
            {
            copiedBook && (
                <CopyAlert />
            )
            }
        </AnimatePresence>
    </div>
  )
}

export default BookDetails