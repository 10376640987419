import { createContext } from "react";
import useLocalStorage from "../Components/lib/UseLocalStorage";

export const SellerReloadContext = createContext()

export function SellerReloadContextProvider({ children }) {
    const [sellerReload, setSellerReload] = useLocalStorage(false)

    return(
        <SellerReloadContext.Provider value={[sellerReload, setSellerReload]} >
            {children}
        </SellerReloadContext.Provider>
    )    
}