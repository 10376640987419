import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// import { LuBadgeHelp } from 'react-icons/lu'

const NoBooks = () => {
  const { t } = useTranslation()
  return (
    <div className='no_book_to_show'>
       <h2 className='title'>{ t("alerts.seller_no_books") }</h2>
       <div><Link to='/book/create-book'>{ t("alerts.seller_no_book_action_link") }</Link> { t("alerts.seller_no_book_action") }</div>
    </div>
  )
}

export default NoBooks
