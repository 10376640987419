import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkMedicalFill } from 'react-icons/bs';
import { HiOutlineRefresh } from 'react-icons/hi';
import ReactCrop from 'react-image-crop';
import { OnboardActions } from './OnboardActions';

const PersonalInfo = ({ user, countryListServer, handle_save_request, index }) => {

	const [firstName, setFirstName] = useState(user?.user.first_name);
	const [lastName, setLastName] = useState(user?.user.last_name);
	const [countryList, setCountryList] = useState(countryListServer)
	const [countryListCopy, setCountryListCopy] = useState(countryListServer)
	const [city, setCity] = useState('')
	const [phoneOne, setPhoneOne] = useState('')
	const [phoneTwo, setPhoneTwo] = useState('')
	const [addOne, setAddOne] = useState('')
	const [addTwo, setAddTwo] = useState('')
	const [profilePic, setProfilePic] = useState('');
	const [country, setCountry] = useState('')
	const [countryDropdownState, setCountryDropdownState] = useState(false)
	const [img, setImg] = useState(null)
	const [selectedCountry, setSelectedCountry] = useState({})
	const [croppedImageSrc, setCroppedImageSrc] = useState(null)
    const [isloading, setIsloading] = useState(false)
    const { t } = useTranslation()
    const [crop, setCrop] = useState({ 
        unit: "px", 
        width: 200, 
        height: 200, 
        aspect: 1,
	shape: 'circle'
    });

    const searchCountryList = (e) => {
		setCountry(e.target.value)
		let remainder = countryListCopy.filter(country => country?.name.toLowerCase().startsWith(e.target.value.toLowerCase()))
		setCountryList(remainder)
	}

    useEffect(() => {
        
        setCountryList(countryListServer)
        setCountryListCopy(countryListServer)

    }, [countryListServer])
    

    const data = {
        "user": user?.user,
        "main_phone_number": phoneOne,
        "alt_phone_number": phoneTwo,
        "country": selectedCountry?.pk,
        "country_name": selectedCountry?.name,
        "city": city,
        "address_line_one": addOne,
        "address_line_two": addTwo,
        "pp": profilePic
    }

    const save_data = () => {
        setIsloading(true)

        if(!selectedCountry?.pk){
            alert(t("seller_onboarding.alerts.country"))
            setIsloading(false)
        }
        else {
            handle_save_request(index, data)
        }
    }
   
    

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        const canvas = document.createElement('canvas');
        const img = document.createElement('img');

        img.src = profilePic;
        img.onload = () => {
            canvas.width = croppedAreaPixels.width;
            canvas.height = croppedAreaPixels.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
            img,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
            );
            canvas.toBlob((blob) => {
                setCroppedImageSrc(blob);
            }, 'image/jpeg');
        };
    };

    const validateUserName = () => {

	}

    
    const selectCountry = (country) => {
		setCountry(country?.name)
		setSelectedCountry(country)
		setCountryDropdownState(false)
	}

    const importImage = (e) => {
        const file = e.target.files[0];
        setImg(file)
        const reader = new FileReader();
        reader.onload = () => {
            setProfilePic(reader.result)
        }
        reader.readAsDataURL(file);
    }
    

    
  return (
    <>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type='text' placeholder={ t("start_selling.fname") } />
            </div>
            <div className='inputContainer'>
                <input value={lastName} onChange={(e) => setLastName(e.target.value)} type='text' placeholder={ t("start_selling.lname") } />
            </div>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <input value={phoneOne} onChange={(e) => setPhoneOne(e.target.value)} type='text' placeholder={ t("start_selling.phone") } />
            </div>
            <div className='inputContainer'>
                <input value={phoneTwo} onChange={(e) => setPhoneTwo(e.target.value)} type='text' placeholder={ t("start_selling.alt_phone") } />
            </div>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <input value={addOne} onChange={(e) => setAddOne(e.target.value)} type='text' placeholder={ t("start_selling.address_one") } />
            </div>
            <div className='inputContainer'>
                <input value={addTwo} onChange={(e) => setAddTwo(e.target.value)} type='text' placeholder={ t("start_selling.address_two") } />
            </div>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <input value={city} onChange={(e) => setCity(e.target.value)} onBlur={validateUserName} 
                    type='text' placeholder={ t("start_selling.city") }
                />
            </div>
            <div className='inputContainer nationality_wrapper'>
                <input value={country} 
                    onChange={e => searchCountryList(e)}
                    onFocus={() => setCountryDropdownState(true)} 
                    onBlur={() =>  setTimeout(() => { setCountryDropdownState(false) }, 500)}
                    type='text' 
                    placeholder={ t("start_selling.nationality") } 
                    autoComplete='off'
                />
                <div className={`countrySelectDropDown ${countryDropdownState ? 'showCountryDropDown' : ''}`}>
                    {
                        countryList.map(element => (
                            <div key={element?.pk} onClick={() => selectCountry(element)} className='nationality_dropdownlist'>
                                {/* <span className={`flag flag-icon flag-icon-cm`}></span> */}
                                <span className='countryName'>{ element?.name }</span>
                            </div>
                        ))
                    }
                    <div className='nationality_dropdownlist loadMoreContries'>
                        <span className='countryName'>load more</span>
                        <span className='flag'><HiOutlineRefresh /></span>
                    </div>
                </div>
            </div>
        </div>
        <div className='profile_picture profile_pic_input'>
            <div className='profile'>
                <h4 className='seller_title'>{ t("start_selling.profile_pic") }</h4>
                    {
                        !profilePic ?
                        (<div className='image_picker'>
                            <input
                                className='input_seller_text'
                                type='file' accept='image/jpeg, image/png, image/gif'
                                onChange={importImage}
                            />
                            <div className='imagePicker_decoy'><BsFileEarmarkMedicalFill /></div>
                        </div>
                        ):
                        (
                            <ReactCrop crop={crop}src={profilePic} aspect={1} className='imageCrop' onChange={newCrop => setCrop(newCrop)} circularCrop onComplete={onCropComplete} >
                                <img src={profilePic} alt="profile pic" />
                            </ReactCrop>
                        )
                    }
                <button className='resetImage_pp' disabled={!profilePic} onClick={() => setProfilePic(null)}>{ t("start_selling.reset") }</button>
            </div>
        </div>
        <OnboardActions save_data={save_data} isloading={isloading} />
    </>
  )
}

export default PersonalInfo