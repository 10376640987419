
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { Player } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { postRequest } from '../../Resquest';
import LoadingPage from '../../LoadingPage';
import { Loader } from './Checkout';
import useLocalStorage from '../lib/UseLocalStorage';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../Context/CartContext';

const PaySuccess = () => {
    const navigate = useNavigate()
    const [paymentStatus, setPaymentStatus] = useState('loading')
    const [cart, setCart] = useContext( CartContext )

    const player = useRef('');

    const endAnimatiion = () => {
        player.current.setSeeker(120, false)
    }

    const [queryParameters] = useSearchParams() 
    const status = queryParameters.get("status") 
    const tx_ref = queryParameters.get("tx_ref")
    const free = queryParameters.get("free")


    const sendRequest = async() => {
        if(free === 'true'){
            return setPaymentStatus(status)
        }
        
        const dataset = {
            "tx_ref":tx_ref,
            "status": status === 'successful' ? 'ACCEPTED': 'FAIL'
        }

        const response = await postRequest('/order-treatment/', dataset)
        console.log("server response", response);
        console.log("your cart", cart);
        response?.status === 202 ? setPaymentStatus('sucessful') : setPaymentStatus('fail')
    }
    
    useLayoutEffect(() => {
        setCart([])
        sendRequest()

    }, [])
    
    const { t } = useTranslation()
    return (
        <div className='email_verify'>
            <div className='container'>
                {
                    paymentStatus === 'loading'?
                    <Loader/>
                    :
                    paymentStatus === 'sucessful' ?
                    <>
                        <div className='email_verify_loader'>
                            <Player
                                onEvent={event => {
                                    if (event === 'complete') endAnimatiion()

                                }}
                                autoplay
                                direction={120}
                                ref={player}
                                src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                                style={{ height: '150px', width: '150px' }}
                            >
                            </Player>
                        </div>
                        <p className='verify_email_text'>{ t("alerts.transaction_success") }</p>
                        <p className='continue_browsing'>
                            <Link to='/my-books'><BsArrowLeft />{ t("user_nav.my_books") }</Link>
                        </p>
                    </>
                    :
                    <>
                        <div className='email_verify_loader'>
                            <Player
                                onEvent={event => {
                                    if (event === 'complete') endAnimatiion()

                                }}
                                autoplay
                                direction={120}
                                ref={player}
                                src="https://lottiefiles.com/animations/failure-error-icon-IyGG3wBm02"
                                style={{ height: '150px', width: '150px' }}
                            >
                            </Player>
                        </div>
                        <p className='verify_email_text' style={{ backgroundColor: "#D0053955", color: '#fff' }}>{ t("alerts.transaction_fail") }</p>
                        <p className='continue_browsing'>
                        </p>
                    </>

                }
            </div>
        </div>
    )
}

export default PaySuccess
