import React, { useState } from 'react'
import { BsChevronDown } from 'react-icons/bs';

const FaqQuestion = ({ question, answer }) => {
        const [showSelfFaq, setShowSelfFaq] = useState(false);
        
  return (
    <div className='frequent_question_dropdown'>
        <div onClick={e => setShowSelfFaq(!showSelfFaq)} className='question_head'>
                <h3 className='question_name'>{ question }</h3>
                <div className='icon'> <BsChevronDown /> </div>
        </div>
        {
                showSelfFaq && (
                        <div className='frequent_question_dropdown_list'>
                                <p className='dropdown_content'>{ answer } </p>
                        </div>
                )
        }
</div>
  )
}

export default FaqQuestion
