import React, { useState } from 'react'
import { FiShoppingCart } from 'react-icons/fi'
import { RiUser6Line } from 'react-icons/ri'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import './NavExplore.css'
import { BsChevronDown, BsGlobe2 } from 'react-icons/bs'
import FeatureDropDown, { FeatureDropDownCategory } from '../lib/FeatureDropDown'
import { useTranslation } from 'react-i18next'

const NavExplore = ({ changeLang, category_list, fetching_cat }) => {
        const [showNav, setShowNav] = useState(false);
        const { t } = useTranslation()
        const navigate = useNavigate()

        
        const [languages, setLanguages] = useState([
                {
                name: "English",
                code: 'en',
                flag: 'gb'
                },
                {
                name: "Français",
                code: 'fr',
                flag: 'fr'
                }
        ]);

  return (
    <div className='non_user_nav'>
        <div className='mobile_nav_n'>
                <div className= {`mobile-top ${ showNav ? 'white-logo' : ''}`} >
                        <div className='logo-burger center'>
                                <a href="/">
                                        <span className='logo-base'  >HooYia</span>
                                </a>
                        </div>
                        <div>
                        </div>
                </div>
                <span onClick={() => setShowNav(!showNav)} className='burger'>
                        <RxHamburgerMenu />
                </span>
                <div className={`mobile_nav_n ${showNav ? 'showNavN' : 'unclip'}`}>
                        <div className='nav_n_logo'>
                                <a href="/">
                                        <span className='logo-base whiteLogo'  >HooYia</span>
                                </a>
                        </div>
                        <ul>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/login' >{ t("non_user_nav.login") }</Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/register' >{ t("non_user_nav.signup") }</Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/explore' >{ t("non_user_nav.explore") }</Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/frequently-asked-question' >{ t("non_user_nav.faq") }</Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link onClick={changeLang} className='nav_link_n' >
                                                languages ( <span  className={`flag-icon flag-icon-gb`}/> / <span className={`flag-icon flag-icon-fr`}/> )
                                        </Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/tour/guide' >{ t("non_user_nav.guide") }</Link>
                                </li>
                        </ul>
                </div>
        </div>
        <div className='nav_desktop_n'>
                <div className='container nav_desk_n'>
                        <div className='nav_n_logo'>
                                <a href="/">
                                        <span className='logo-base whiteLogo'  >HooYia</span>
                                </a>
                        </div>
                        <ul>
                                {/* <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/' >{ t("non_user_nav.home") }</NavLink>
                                </li> */}
                                <li className='nav_list_n explore_btn_n category_wrapper_n'>
                                        <div className='explore_link_n' >{ t("dashboard_edit_form.category") } <span className='icon'> <BsChevronDown /> </span></div>
                                        <FeatureDropDownCategory category_list={category_list} />
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/ebooks?auth=false' >{ t("non_user_nav.browse") }</NavLink>
                                </li>
                                <li className='nav_list_n explore_btn_n'>
                                        <div onClick={() => navigate('/explore')} className='explore_link_n' >{ t("non_user_nav.explore") } <span className='icon'> <BsChevronDown /> </span></div>
                                        <FeatureDropDown />
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/frequently-asked-question' >{ t("non_user_nav.faq") }</NavLink>
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/tour/guide' >{ t("non_user_nav.guide") }</NavLink>
                                </li>
                                <li className='nav_list_n language_drop_container'>
                                        <NavLink className='nav_link_n' >{ t("non_user_nav.language") }</NavLink>
                                        <div className='language_dropdown_n'>
                                                <ul>
                                                {
                                                        languages.map((language, index) => (
                                                        <li key={index} className="dropdownNav-item">
                                                                <span  onClick={(e) => changeLang(language.code, e)} className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                <span  onClick={(e) => changeLang(language.code, e)} className='blacktext'>{language.name}</span>
                                                        </li>
                                                        ))
                                                }
                                                </ul>
                                        </div>
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/login' >{ t("non_user_nav.login") }</NavLink>
                                </li>
                                <li className='nav_list_n signup_li'>
                                        <NavLink className='nav_link_n signup_btn_n' to = '/register' >{ t("non_user_nav.signup") }</NavLink>
                                </li>
                        </ul>
                </div>
        </div>
        
      
    </div>
  )
}

export default NavExplore
