import React from 'react'

const ImageDiscount = ({ image }) => {
  return (
    <div className='imageWrapper'>
      <img className='navLinkImage' src={ image } alt='' />
    </div>
  )
}

export default ImageDiscount
