import React from 'react'
import { Link } from "react-router-dom";

const NotSellerDashboard = () => {
  return (
    <div>
        
      you are not a seller. <Link to='/seller-onboarding/seller-details'>click here </Link> to become a seller 
    </div>
  )
}

export default NotSellerDashboard
