import i18next from 'i18next';
import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const EarningTable = ({ earning }) => {
    const [months, setmonths] = useState([])
    const {t} = useTranslation()

    useLayoutEffect(() => {
        let monthsEn = [ "January", "February", "March", "April", "May", "June",  "July", "August", "September", "October", "November", "December" ];
        let monthsFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
      const lang = i18next.language
      setmonths(lang === 'en' ? monthsEn : monthsFr)
    }, [])
  return (
    <table>
        <thead>
            <tr>
                <th>{ t("dashboard_earning.transactions_table.pay_ammount") }</th>
                <th>{ t("dashboard_earning.transactions_table.transaction_id") }</th>
                <th>{ t("dashboard_earning.transactions_table.pay_date") }</th>
                <th>{ t("dashboard_earning.transactions_table.activity") }</th>
                <th>{ t("dashboard_earning.transactions_table.fund_state") }</th>
            </tr>
        </thead>
        {
            earning.length === 0
            ?
            <h3 className='no_earnings'>{ t("nothing_sold") }</h3>
            :
            <tbody>
                {
                    earning?.map(earn => (
                        <tr>
                            <td>{earn.amount_earned}</td>
                            <td>{earn.id}</td>
                            <td>{months[earn.month - 1]} {earn.year}</td>
                            <td>{
                                earn.is_paid
                                ? <span className='earning_activity spend'>
                                    { t("dashboard_earning.expenses") }</span>
                                : <span className='earning_activity earn'>
                                    { t("dashboard_earning.earn") }</span>
                            }</td>
                            <td>{
                                earn.is_withdrawable
                                ? <span className='earning_activity earn'>{ t("dashboard_earning.transactions_table.withdraw_active") }</span>
                                : <span className=''>{ t("dashboard_earning.transactions_table.withdraw_pending") }</span>
                            }</td>
                        </tr>
                    ))
                }
                
            </tbody>

        }
    </table>
  )
}

export default EarningTable