import React, { useRef } from 'react'

import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PasswordResetLinkExpired = () => {
    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'></div>
                <p className='verify_email_text'>Password reset link is expired. Go to the password reset form and re-enter your email</p>
                <p className='continue_browsing'>
                    <Link to='/forgot-password'><BsArrowLeft />Go to form</Link>
                </p>
            </div>
        </div>
    )
}

export default PasswordResetLinkExpired
