import React, { createContext, useEffect, useLayoutEffect } from 'react'
import useLocalStorage from '../Components/lib/UseLocalStorage';
import { getRequest, postRequest } from '../Resquest';

export const WishListContext = createContext()

export const WishListContextProvider= ({ children }) => {
    const [wishList, setWishList] = useLocalStorage('wishList', []);

    useLayoutEffect(() => {
      return () => {
        getList()
      };
    }, [])
    
    useEffect(() => {
      return () => {
        updateList()
      }
    }, [wishList])

    const getList = async () => {
        const response = await getRequest(`/cart-item/`)
		if (response.statusText === 'OK') {
            setWishList(response.data.items)
        } else console.log('getlist.bad...',response)
    }
    
    const updateList = async () => {
        const item = window.localStorage.getItem("wishList")
        const data = {
            "products" : item && JSON.parse(item).map(book => book.slug) || []
        }
        console.log("data...", data)
		const response = await postRequest(`/cart-item/`, data)
		response?.statusText === 'OK'
			? console.log('Update.good....',response)
			: console.log('Update.bad....',response)
    }
    
    return (
        <WishListContext.Provider value={[wishList, setWishList]}>
            {children}
        </WishListContext.Provider>
    )
}