export const getSessions = (t) => [
    {
      id: 1,
      name: t('subscribe_section.sessions.0.name'),
      periods: [
        { id: 1, name: t('subscribe_section.sessions.0.periods.0.name'), price: t('subscribe_section.sessions.0.periods.0.price') },
        { id: 2, name: t('subscribe_section.sessions.0.periods.1.name'), price: t('subscribe_section.sessions.0.periods.1.price') },
        { id: 3, name: t('subscribe_section.sessions.0.periods.2.name'), price: t('subscribe_section.sessions.0.periods.2.price') },
      ],
    },
    {
      id: 2,
      name: t('subscribe_section.sessions.1.name'),
      periods: [
        { id: 4, name: t('subscribe_section.sessions.1.periods.0.name'), price: t('subscribe_section.sessions.1.periods.0.price') },
        { id: 5, name: t('subscribe_section.sessions.1.periods.1.name'), price: t('subscribe_section.sessions.1.periods.1.price') },
        { id: 6, name: t('subscribe_section.sessions.1.periods.2.name'), price: t('subscribe_section.sessions.1.periods.2.price') },
      ],
    },
    {
        id: 3,
        name: t('subscribe_section.sessions.1.name'),
        periods: [
          { id: 7, name: t('subscribe_section.sessions.1.periods.0.name'), price: t('subscribe_section.sessions.1.periods.0.price') },
          { id: 8, name: t('subscribe_section.sessions.1.periods.1.name'), price: t('subscribe_section.sessions.1.periods.1.price') },
          { id: 9, name: t('subscribe_section.sessions.1.periods.2.name'), price: t('subscribe_section.sessions.1.periods.2.price') },
        ],
      },
    
  ];
  