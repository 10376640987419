import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import './NavSeller.css'
import { IoIosLogOut } from 'react-icons/io'
import { IoNotificationsOutline, IoPersonOutline } from 'react-icons/io5'
import { AiFillCaretDown, AiOutlineUser } from 'react-icons/ai'
import { TbHexagonLetterH } from 'react-icons/tb'
import { BsCashStack, BsChatSquareDots, BsPlusCircle } from 'react-icons/bs'
import { IsSellercontext } from '../../../Context/IsSellerContext'

const NavSeller = ({user, handleRemoveAuthModal}) => {
        const [showNav, setShowNav] = useState(false);
        const [sideNav, setSideNav] = useState(false);
        const [isSellerAccount, setIsSellerAccount] = useContext(IsSellercontext)
        
        const navigate = useNavigate()
       const removeNav = (e) => {
                e.target.classList.contains('seller_navLink') && setShowNav(false)
       }
       const switchToBuying = () => {
                navigate('/')
                setIsSellerAccount(false)
       }
       const { t } = useTranslation()
  return (
        <>
        <div className='nav_home'>
                <div className='container flexHead'>
                        <div className='nav_home_mobile_head'>
                                <Link to={`/${user?.user?.first_name}/dashboard`}>
                                        <span className="logo-base logo-white">HooYia</span>
                                </Link>
                                <div onClick={() => {setShowNav(!showNav)}} className='burger'>
                                        <RxHamburgerMenu />
                                </div>
                        </div>
                        <div className={`shadow ${showNav && 'showShadow'}`} onClick={() => setShowNav(false)} />
                        <div className={`nav_home_content ${ showNav ? 'showNavHome': '' }`}>
                                <ul onClick={ (e) => { removeNav(e) } } className='nav_home_navLinks'>
                                        <li>
                                                <NavLink className='seller_navLink' to={`/${user?.user?.first_name}/dashboard`}>{ t("dashboard.dashboard") }</NavLink>
                                        </li>
                                        <li className='dropdownNav_seller_m'>
                                                <Link className='seller_navLink' to={`/${user?.user?.first_name}/analytics`} >{ t("dashboard.analytics") }</Link>
                                        </li>
                                        <li className='desktop'>
                                                <div className='linkTopWrapper dropdown_anchore'>
                                                        <p className='dropdown_text_head'>
                                                                <span className='linkTop' >{ t("dashboard.analytics") }</span>
                                                                <span className='iconB'>
                                                                        <AiFillCaretDown />
                                                                </span>
                                                        </p>
                                                        <div className='dropdownNav'>
                                                                <div onClick={() => navigate(`/${user?.user?.first_name}/earnings`)} className='dropdownNav-item'>{ t("dashboard.earning") }</div>
                                                                <div onClick={() => navigate(`/${user?.user?.first_name}/manage-sales?param=orders`)} className='dropdownNav-item'>{ t("dashboard.order") }</div>
                                                                <div onClick={() => navigate(`/${user?.user?.first_name}/discount`)} className='dropdownNav-item'>{ t("dashboard.business") }</div>
                                                                <div className='dropdownNav-item'>{ t("dashboard.selling") }</div>
                                                        </div>
                                                </div>
                                        </li>
                                        <li className='mobile_seller'>
                                                <NavLink className='seller_navLink' to={`/${user?.user?.first_name}/dashboard`}
                                                        onClick={() => switchToBuying()}>{ t("dashboard.switch") }</NavLink>
                                        </li>
                                        <li className='mobile_seller'>
                                                <NavLink className='seller_navLink' to={`/${user?.user?.first_name}/manage-sales?param=orders`}>{ t("dashboard.order") }</NavLink>
                                        </li>
                                        <li className='mobile_seller'>
                                                <NavLink className='seller_navLink' to={`/${user?.user?.first_name}/earnings`}>{ t("dashboard.earning") }</NavLink>
                                        </li>
                                        <li className='mobile_seller'>
                                                <NavLink className='seller_navLink' to={`/${user?.user?.first_name}/settings`}>{ t("dashboard.settings") }</NavLink>
                                        </li>

                                        <li>
                                                <NavLink className='seller_navLink' to={`/frequently-asked-question`}>{ t("dashboard.help") }</NavLink>
                                        </li>
                                        <li>
                                                <NavLink className='seller_navLink add_book_link' to='/book/create-book'>
                                                        <span className='iconB'>
                                                                <BsPlusCircle />
                                                        </span>
                                                        <span className='linkTop' >{ t("dashboard.add") }</span>
                                                </NavLink>
                                        </li>
                                        <li className='mobile_seller'>
                                                <NavLink className='seller_navLink' to='/contact-us'>Contact Us</NavLink>
                                        </li>
                                        <li className='desktop'>
                                                <div className='linkTopWrapper dropdown_anchore'>
                                                        <div className='account_link_ta'>
                                                                <span className='iconB iconApp'>
                                                                        <AiOutlineUser />
                                                                </span>
                                                                <span className='linkTop'>{ user?.user?.first_name }</span>
                                                        </div>
                                                        <div className='dropdownNav'>
                                                                <div onClick={() => navigate(`/${user?.user?.first_name}`)} className='dropdownNav-item'>Profile</div>
                                                                <div className='dropdownNav-item' onClick={()=>switchToBuying()}>{ t("dashboard.switch") }</div>
                                                                <div className='dropdownNav-item'>{ t("dashboard.switch_account") }</div>
                                                                <div onClick={() => navigate(`/${user?.user?.first_name}/profile`)} className='dropdownNav-item'>{ t("dashboard.settings") }</div>
                                                                <div onClick={ handleRemoveAuthModal } className='dropdownNav-item logoutNavLink'>
                                                                        <span className='accountIcon'>
                                                                                <IoIosLogOut />
                                                                        </span>
                                                                        { t("dashboard.logout") }
                                                                </div>
                                                        </div>
                                                </div>
                                        </li>
                                </ul>
                        </div>
                        
                </div>
        </div>
        <aside className={`seller_nav_aside ${sideNav ? 'scale_side_nav': ''}`}>
                <div onClick={() => {setSideNav(!sideNav)}} className='burger nav_aside_link'>
                        <span className='nav_aside_icon'>
                                <TbHexagonLetterH />
                        </span>
                        <span className='nav_aside_text'>
                                Open
                        </span>
                </div>
                <div onClick={() => {navigate(`/${user?.user?.first_name}/manage-sales?param=orders`)}} className='burger nav_aside_link'>
                        <span className='nav_aside_icon'>
                                <BsCashStack />
                        </span>
                        <span className='nav_aside_text'>
                        { t("dashboard.earning") }
                        </span>
                </div>
                <div onClick={() => {navigate(`/${user?.user?.first_name}/chat`)}} className='burger nav_aside_link'>
                        <span className='nav_aside_icon'>
                                <BsChatSquareDots />
                        </span>
                        <span className='nav_aside_text'>
                                Chats
                        </span>
                </div>
                <div onClick={() => {navigate('/updates')}} className='burger nav_aside_link'>
                        <span className='nav_aside_icon'>
                                <IoNotificationsOutline />
                        </span>
                        <span className='nav_aside_text'>
                                { t("dashboard.notification") }
                        </span>
                </div>
                <div onClick={() => {navigate('/book/create-book')}} className='burger nav_aside_link'>
                        <span className='nav_aside_icon'>
                                <BsPlusCircle />
                        </span>
                        <span className='nav_aside_text'>
                        { t("dashboard.add") }
                        </span>
                </div>
                <div onClick={ handleRemoveAuthModal } className='dropdownNav-item nav_aside_link logoutNavLink'>
                        <span className='nav_aside_icon'>
                                <IoIosLogOut />
                        </span>
                        <span className='nav_aside_text'>
                        { t("dashboard.logout") }
                        </span>
                </div>
        </aside>
        </>
  )
}

export default NavSeller
