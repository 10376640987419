import React, { useEffect, useLayoutEffect, useState } from 'react'
import './Ebook.css'
import ProductGrid from '../../Products/ProductGrid'
import Search from '../../Addons/Search/Search'
import Pagination from '../../Addons/Pagination'
import { getRequest, getRequestWithParams } from '../../../Resquest'

import { InstantSearch, RefinementList, Hits, SearchBox } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next'
import { searchClient } from '../../../searchClient'

import { ReactComponent as EbooksSvg } from '../../../Assets/img/ebook_svg.svg';
import LoadingPage from '../../SELLER/Dashboard/Components/LoadingPage'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { setMetaDescription } from '../../..'


const Ebooks = () => {
	const [ebooks, setEbooks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [bookCount, setBookCount] = useState(10)
	const [limit, setLimit] = useState(9)
	const [page, setPage] = useState(1)
	const [prev_url, setPrev_url] = useState('')
	const [next_url, setNext_url] = useState('')

	const location = useLocation()

	// useLayoutEffect(() => {
	// 	getBooks()
	// }, [currentPage]);

	useLayoutEffect(() => {
		getBookWithCat()
	}, [location]);

	const getBookWithCat = async () => {
		setIsLoading(true)
		let response;
		let category_id = location.state?.dir
		category_id ?
			(
				response = await getRequest(`/public/ebook/display/?categories=${category_id}`)
			)

		: (
			response = await getRequest('/public/ebook/display/')
		)
		
		response.statusText === "OK"
			? saveData(response.data)
			: console.warn(response)
	}

	const getBooks = async () => {
		setIsLoading(true)
		const params = {
			"limit": 10,
			"offset": (currentPage - 1) * 10
		}
		const response = await getRequest(`/public/ebook/display/?limit=${limit}&offset=${(currentPage - 1) * limit}`)
		setIsLoading(false)
		saveData(response.data)
	}

	
	
	const requestPageByOffset = async(page) => {
		setIsLoading(true)
		const response = await getRequest(`/public/ebook/display/?offset=${(page - 1) * (limit + 1)}`)
		window.scrollTo(0, 0); 

		if(response.status === 200){
			setCurrentPage(page + 1)
			saveData(response.data)
		}
	}
	
	const saveData = (ebook_data) => {
		setEbooks(ebook_data?.results)
		setBookCount(ebook_data?.count)
		setNext_url(ebook_data?.next)
		setPrev_url(ebook_data?.previous)
		setIsLoading(false)

	}

	const loadNextPage = (page) => {
		requestPageByOffset(page)
	}

	const { t } = useTranslation();

	const queryHook = (query, search) => {
		search(query);
		query.length > 0 ? setIsSearching(true) : setIsSearching(false)
	};

	useEffect(() => {
	  !prev_url && setPage(1)
	}, [])
	


	const change_page = async(url) => {
		if(!url){
			return 
		}
		window.scrollTo(0, 0); 
		setIsLoading(true)
		const config = {
			headers: {
				"Content-Type": "application/json",
			}
		}
		try {
			const response = await axios.get(url, config)
			if(response.status === 200){
				saveData(response.data)
				setPage(page + 1)
			}
		} catch (err) {
			return err
		}
	}

	useEffect(() => {
		const bookDescription = t("randoms.ebook_description")
		const ogBookDescription = t("randoms.ebook_description")
		
		setMetaDescription(bookDescription, ogBookDescription);
		
		return () => {
		  setMetaDescription('', '');
		};
	}, []);
	
	
	return (
		<InstantSearch searchClient={searchClient} indexName="hooyia_index_ebook" insights={true}>
			<div className='ebook_n'>
				<div className='container'>
					{/* <Search /> */}
					<SearchBox queryHook={queryHook} className='search_n_user' placeholder='search...' />
					{/* */}
					<div className='ebook_grid_n'>

						<div className={`ebook_list_n ${isSearching ? 'showaside' : ''} `}>
							{
								isSearching ? (
									<div className='search_result_non_user'>
										<Hits hitComponent={Hit} />
									</div>
								) : (
									<div className='no_search_aside'>
										<div className='title'>{t('non_user_ebooks.title')}</div>
										<p>{t('non_user_ebooks.text')}</p>
										<div className='svg_login'>
											<EbooksSvg />
										</div>
										<p>{t('non_user_ebooks.more_text')}</p>
									</div>
								)
							}



						</div>
						<div className='ebook_subgrid_n'>
							{
								isLoading
									? (
										<LoadingPage />
									) : (
										<ProductGrid products={ebooks} />
									)
							}
						</div>
					</div>
					<div className='pagination_btn_wrapper'>
						<a type='button' className={`${!prev_url ? 'deactivate_btn': ''}`} onClick={() =>change_page(prev_url)}>{ t("prev_btn") }</a>
						<p>{page}</p>
						<a  type='button' className={`${!next_url ? 'deactivate_btn': ''}`} onClick={() =>change_page(next_url)}>{ t("next_btn") }</a>
					</div>
					{/* <Pagination count={Math.ceil(bookCount/limit)} handlePageCount={loadNextPage} /> */}
				</div>
			</div>
		</InstantSearch>
	)
}

export default Ebooks

const Hit = ({ hit }) => {
	console.log('hits component', hit);
	const navigate = useNavigate()
	return (
		<div onClick={() => navigate(`/ebooks/${hit?.objectID}`)}>
			{/* <hit attribute="brand" /> */}
			<div className='hit_grid'>
				<div className='hit_item_image'>
					<img className='img' src={'https://e-hooyia.s3.amazonaws.com/' + hit?.cover} alt={hit?.name} />
				</div>
				<div className='hit_desc'>
					<p className='hit_title'>{hit?.name}</p>
					<p className='hit_auth'>By: {hit?.author}</p>
				</div>
			</div>
		</div>
	)
}