import React, { useEffect, useLayoutEffect, useState } from 'react'
import './Home.css'
import { HiChevronDown, HiOutlineLocationMarker } from 'react-icons/hi'
import SuperDeals from '../../Assets/img/super_deals.webp'
import Watch from '../../Assets/img/41pOKvNMdnL._SX355_BO1,204,203,200_.jpg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ImageDiscount from './ImageDiscount'
import { FiArrowRight } from 'react-icons/fi'
import { BsStarFill } from 'react-icons/bs'
import Off from '../Addons/Off'
import ProductGrid from '../Products/ProductGrid'
import { getRequest } from '../../Resquest'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Pagination from '../Addons/Pagination'
import { tabTitle } from '../..'
import { FeatureDropDownCategory } from '../NON-USER/lib/FeatureDropDown'
import { HiChevronRight } from 'react-icons/hi2'


const Home = ({ category_list, fetching_cat }) => {
	const [ebooks, setEbooks] = useState([]);
	const [isloading, setIsloading] = useState(false)
	const [page, setPage] = useState(1)
	const [bookCount, setBookCount] = useState(1)
	const [limit, setLimit] = useState(9)
	const [next_url, setNext_url] = useState('')
	const [prev_url, setPrev_url] = useState('')
	const [currentPage, setCurrentPage] = useState(1)

	const location = useLocation()
	const navigate = useNavigate()

	tabTitle("HooYia Ebooks - achetez et vendez des livres de qualité en Afrique et dans le monde")

	useLayoutEffect(() => {
		getBooks()
	}, [location]);

	if (location?.state?.logout){
		window.location.reload()
	}

	useEffect(() => {
		console.log(currentPage);
		!prev_url && setPage(1)
	  }, [])

	  
	const getBooks = async () => {
		setIsloading(true)
		let response;
		let category_id = location.state?.dir
		location.search ?
			(
				response = await getRequest(`/public/ebook/display/?categories=${category_id}`)
			)

		: (
			response = await getRequest('/public/ebook/display/')
		)
		response.statusText === "OK"
			? saveData(response.data)
			: console.warn(response)
	}


	const saveData = (data) => {
		setEbooks(data?.results)
		setNext_url(data?.next)
		setPrev_url(data?.previous)
		setIsloading(false)
		setBookCount(data?.count)
	}

	const change_page = async(url) => {
		if(!url){
			return 
		}
		window.scrollTo(0, 0); 
		setIsloading(true)
		const config = {
			headers: {
				"Content-Type": "application/json",
			}
		}
		try {
			const response = await axios.get(url, config)
			if(response.status === 200){
				saveData(response.data)
				setPage(page + 1)
			}
		} catch (err) {
			return err
		}
	}

	const loadNextPage = (page) => {
		requestPageByOffset(page)
	}
	
	const requestPageByOffset = async(page) => {
		setIsloading(true)
		const response = await getRequest(`/public/ebook/display/?offset=${(page - 1) * (limit + 1)}`)
		window.scrollTo(0, 0); 

		if(response.status === 200){
			setCurrentPage(page + 1)
			saveData(response.data)
		}
	}

	let discountedProduct = ebooks.filter(ebook => (ebook?.discount_percentage) > 2 )
	discountedProduct = discountedProduct.length > 6 ? discountedProduct.slice(0,6) : discountedProduct
	const dp1 = discountedProduct.slice(0,3)
	const dp2 = discountedProduct.slice(3)

	const category_item_firsts = category_list.slice(0, 10);
	const secondArray = category_list.slice(10);

	const { t } = useTranslation()
	return (
		<div className='container'>
			<div className='home_category_list'>
				<ul>
					{
						category_item_firsts?.map(cat => (
							<li key={ cat.pk } className='category_item'>
								<a onClick={()=> navigate('/ebooks?category='+ cat?.name, {state: {"dir": cat?.name}})} >{ cat?.name }{ cat?.children.length > 0 && <span className='cat_dropdown_indicator'><span><HiChevronDown /></span></span> }</a>
								{
									cat?.children.length > 0 && (
										<div className='drop_cat_list home'>
											{
											cat?.children?.map(child_cat => (
												<li key={child_cat } className='category_item'>
													<a onClick={()=> navigate(`/ebooks?category=${cat?.name}-${child_cat}`, {state: {"dir": cat?.name}})} >{ child_cat }</a>
												</li>
											))
											}
										</div>
									)

								}
							</li>
						))
					}
					{
						!isloading && <MoreCategories car_list={ secondArray } />
					}
					
				</ul>
			</div>
			<div className='deals'>
				<div className='super_deals'>
					<img src={SuperDeals} alt='super deals' />
				</div>
				<div className='span_device'>
					<div className='categories'>
						<div className='category'>
							<div className='desc'>{ t("ebooks.recommended_for_you") }</div>
							<LoadSuperDeals deals={dp1} isloading={isloading} />
						</div>
						<div className='category'>
							<div className='desc'>{ t("ebooks.limited_time_low") }</div>
							<LoadSuperDeals deals={dp2} isloading={isloading} />
						</div>
						<div className='category category_view_more'>
							<div className='desc'>{ t("ebooks.view_more") } <span className='icon'> <FiArrowRight /> </span></div>
						</div>
					</div>

				</div>
			</div>

			<div className='more_to_love'>
				<div className='head '>{ t("ebooks.more_to_love") }</div>
				<ProductGrid products={ebooks} isloading={isloading} />
			</div>
			<div className='pagination_btn_wrapper'>
				<a type='button' className={`${!prev_url ? 'deactivate_btn': ''}`} onClick={() =>change_page(prev_url)}>{ t("prev_btn") }</a>
				<p className='pagination_nom_mobile_'>{page}</p>
				<p className='pagination_nom_desktop_'>
					<Pagination count={Math.ceil(bookCount/limit)} currentPage={currentPage} handlePageCount={loadNextPage} /> 
				</p>
				<a  type='button' className={`${!next_url ? 'deactivate_btn': ''}`} onClick={() =>change_page(next_url)}>{ t("next_btn") }</a>
			</div>
		</div>
	)
}

export default Home


const MoreCategories = ({ car_list }) => {
	const navigate = useNavigate()
	const [showMore, setShowMore] = useState(false)

	return (
		<>
		{ showMore && <div className='category_shadow' onClick={ () => setShowMore(false)} /> }
		<li  className='category_item category_item_more'>
			<span onClick={() => setShowMore(!showMore)} className='dropdown_anchor'>more</span>
			<div className={`category_dropdown_nu ${ showMore ? 'show_category_dropdown_nu' : '' }`}>
				{
					car_list.map(cat => (
					<a key={cat.pk} onClick={()=> navigate('/ebooks?category='+ cat?.name, {state: {"dir": cat?.name}})}   className={`category_nu_link ${cat?.children.length > 0 ? "sub_down": ""}`}>
							<div className='cat_title_nu'><span>{ cat.name }</span>{ cat?.children.length > 0 && <span className='cat_dropdown_indicator_nu'><span><HiChevronRight  /></span></span> }</div>
							<div className='sub_down_category_nu'>
							{
									cat?.children.length > 0 && (
											<div className='drop_cat_list_nu'>
													{
													cat?.children?.map(child_cat => (
															<li key={child_cat } className='category_item'>
																	<a onClick={()=> navigate(`/ebooks?category=${cat?.name}-${child_cat}`, {state: {"dir": cat?.name}})} >{ child_cat }</a>
															</li>
													))
													}
											</div>
									)
							}
							</div>

					</a>
					))
				} 
			</div>
		</li>
		</>
	)
	
}

const LoadSuperDeals = ({ deals, isloading }) => {

	let placeholder_dp = new Array(3).fill(0)
	return (
		<>
		{
			isloading ? 
			placeholder_dp.map((i, index) => (
			<div key={index}  style={{ width: '100%' }}>
				<Link className='discountProduct'>
				<div className='imageWrapper loading_skeleton'>
				</div>
					<div>
						<span className='discount_product_name loading_skeleton skeleton_text'/>
						<p className='price loading_skeleton skeleton_text'></p>
						<span className='price-discount skeleton_off loading_skeleton'></span>
					</div>
				</Link>
			</div>
			))
			:
			deals.map((product, index) => (
				<div key={index} style={{ width: '100%' }}>
					<SuperDealProductCalc product={product} />
				</div>
			))
			
		}
		</>
	)
}


const SuperDealProductCalc = ({ product }) => {
	const [demoPrice, setDemoPrice] = useState(product?.price)
	useLayoutEffect(() => {
		let updated_price = product.discount_percentage > 0 && parseInt(product.price) + (parseInt(product.discount_percentage)/100) * product.price 
		setDemoPrice(updated_price)
	}, [])
	return (
		<Link className='discountProduct' to={`/ebooks/${product.slug}`}>
			<ImageDiscount image={product.cover} />
			<div>
				<span className='discount_product_name'>{product.name}</span>
				<p>
					<span className='price' style={{ marginRight: '10px'}}>XAF {product.price}</span>
					<span className='price priceOld'>XAF { demoPrice }</span>
				</p>
				<Off discount={product.discount_percentage} />
			</div>
		</Link>
	)
}

