import React, { useRef, useEffect } from 'react'

import { Player } from '@lottiefiles/react-lottie-player';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SellerAccountSuccess = () => {
    const player = useRef('');

    useEffect(() => {
        request_user()
    }, [])

    const request_user = () => {
        localStorage.removeItem("user")
        window.location.href = '/login'

    }
    
    const endAnimatiion = () => {
        player.current.setSeeker(120, false)
    }


    const { t } = useTranslation()

    return (
        <div className='email_verify'>
            <div className='container'>
                <div className='email_verify_loader'>
                    <Player
                        onEvent={event => {
                            if (event === 'complete') endAnimatiion()

                        }}
                        autoplay
                        direction={120}
                        ref={player}
                        src="https://assets4.lottiefiles.com/packages/lf20_bx4oomul.json"
                        style={{ height: '150px', width: '150px' }}
                    >
                        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                </div>
                <p className='verify_email_text'>{ t("alerts.alert_seller") }</p>
                <p className='continue_browsing'>
                    <Link to='/login'><BsArrowLeft />{ t("alerts.login") }</Link>
                </p>
            </div>
        </div>
    )
}

export default SellerAccountSuccess