import React, { useMemo, useState } from 'react'
import Select from 'react-select'

const options = [
        {
                label: "English", 
                value: "E",

        },
        {
                label: "Francais", 
                value: "F",

        }
]
const LanguageSelect = ({ handleLanguageSelect }) => {
        const [value, setValue] = useState(options[0])
        const [selectedOption, setSelectedOption] = useState(options[0]);

        const changeHandler = value => {
                setValue(value)
                handleLanguageSelect(value);

        }
        

        return (
                <>
                        <Select defaultValue={selectedOption} options={options} value={value} onChange={changeHandler} />
                </>
        )
}

export default LanguageSelect
