import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import ReactCrop from 'react-image-crop'
import './Book.css'
import LanguageSelect from '../../Addons/LanguageSelect'
import { VscChromeClose } from 'react-icons/vsc'

import { auth, getRequest, patchRequest, postFetchRequest, postRequest } from '../../../Resquest'
import CategorySelect from '../../Addons/CategorySelect'
import { useLocation, useNavigate } from 'react-router-dom'
import { CiImageOn } from 'react-icons/ci'
import { BsCardImage, BsFileEarmarkMedicalFill } from 'react-icons/bs'
import PopupAddToAlert from '../../Addons/PopupAddToAlert'
import { ReactComponent as Loader } from './LoaderOval.svg'

const BookCreate = ({ user }) => {
	const [title, setTitle] = useState('');
	const [category, setCategory] = useState('');
	const [price, setPrice] = useState(0);
	const [description, setDescription] = useState('');
	const [author, setAuthor] = useState('')
	const [language, setLanguage] = useState('');
	const [coverPhoto, setCoverPhoto] = useState();
	const [coverPhUrl, setCoverPhUrl] = useState();
	const [bookFile, setBookFile] = useState('');
	const [serverCategories, setServerCategories] = useState([]);
	const [tags, setTags] = useState([]);
	const [tagString, setTagString] = useState('');
	const [allGood, setAllGood] = useState(false)
	// const [progress, setProgress] = useState(0);
	const [isloading, setIsloading] = useState(false)
	const [isPatch, setIsPatch] = useState(false)
	const [displayAlert, setDisplayAlert] = useState(false)
	const [alertMessage, setalertMessage] = useState("")
	const [bookManager, setBookManager] = useState('');
	const [crop, setCrop] = useState({
		unit: '%', // Can be 'px' or '%'
		x: 8,
		y: 8,
		width: 85,
		height: 85
	})
	const [previewFile, setPreviewFile] = useState(null)

	const location = useLocation()

	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		if (location.state) {
			setTitle(location.state.name)
			setCategory(location.state.categories)
			setPrice(location.state.price)
			setDescription(location.state.description)
			location.state.tags && setTags(location.state.tags)
			setPrice(location.state.price)
			setCoverPhUrl(location.state.cover)
		}

		requestCategories();

	}, []);

	const requestCategories = async () => {
		const response = await getRequest('/public/list-category/')
		response?.data
			&& setServerCategories(response?.data?.results)

	}
	
	const languageSelect = (lang) => {
		setLanguage(lang.value)
	}

	const selectCategory = (cat) => {
		setCategory(cat.uuid)
	}
	
	const submitForm = async (e) => {
		e.preventDefault()
		const formData = new FormData()
		formData.append("categories", category)
		formData.append("name", title)
		formData.append("author", author)
		formData.append("language", language)
		formData.append("description", description)
		formData.append("audio_video", "")
		formData.append("price", price)
		formData.append("author", author)
		formData.append("visibility", "V")
		formData.append("tags_list", tags.slice(0, 5))
		coverPhoto && formData.append("cover", coverPhoto, coverPhoto.name)
		bookFile && formData.append("doc", bookFile)
		let endpoint

		if (location.state?.name ) {
			endpoint = `https://hooyia.herokuapp.com/api/v1/seller/ebook/owner-ebook/${location.state.slug}/`
			setIsPatch(true)
		} else {
			endpoint = 'https://hooyia.herokuapp.com/api/v1/seller/ebook/owner-ebook/'
			setIsPatch(false)
		}

		const xhr = new XMLHttpRequest();
		isPatch ? xhr.open('PATCH', endpoint, true) : xhr.open('POST', endpoint, true);

		xhr.setRequestHeader('Authorization', 'Bearer ' + user.access);

		xhr.upload.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentComplete = (event.loaded / event.total) * 100;
				setIsloading(true)
			}
		};

		xhr.onload = () => {
			if (xhr.statusText === "Created" || xhr.statusText === "OK") {
				setalertMessage( t("dashboard_bookcreate.alerts.book_created") );
				alertMessage ? setDisplayAlert(true) : setDisplayAlert(false)
				setIsloading(false)
				window.location.href = '/?book-created=true'
			} else {
				setalertMessage( t("dashboard_bookcreate.alerts.book_create_error") );
				alertMessage ? setDisplayAlert(true) : setDisplayAlert(false)
				setIsloading(false)
			}
			console.log(xhr);
		};

		console.log(xhr.send(formData))
	}
	
	const controlTagList = (e) => {
		e.preventDefault()
		setTags(tags => [...tags, tagString])
		setTagString('')
	}

	const removeTag = (tag) => {
		let filteredTag = tags.filter(tagItem => tag !== tagItem)
		setTags(filteredTag)
	}

	const handleFileInput = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith('image/')) {
			if (file.name.includes(".png") || file.name.includes(".jpeg") || file.name.includes(".jpg")) {
				setCoverPhoto(file)
			}
			else {
				let fname = file.name
				let flen = file.name.length
				let msg = `File extension ${fname[flen-4] == "." ? fname.substring(flen-4) : 
				fname.substring(flen-5)} is not allowed. Allowed extensions are: png, jpeg, and jpg`
				setalertMessage(msg)
				alertMessage ? setDisplayAlert(true) : setDisplayAlert(false)
			}
			// setSelectedImage(URL.createObjectURL(file));
		}
	};

	const handleFileChange = (e) => {
		setBookFile(e.target.files[0])
		const file = e.target.files[0];
    	setPreviewFile(URL.createObjectURL(file));
	}

	const onClose = () => {
		setDisplayAlert(false)
		setalertMessage("")
	}

	return (
		<div className='seller_create_book'>
			<div className='container flex_create_book'>
				<div className='create_book_form'>
					<div className='book_title container'>{location.state ? `${ t("dashboard_bookcreate.state_edit") } ${location.state.name}` : t("dashboard_bookcreate.state_create") }</div>
					<div className='create_bookWrapper'>
						<form onSubmit={submitForm}>
							{/* {displayAlert && <AlertModal message={"something"} close={onClose}/>} */}
							<PopupAddToAlert showPopup={displayAlert} handleRemoveModal={onClose} message={alertMessage} />
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.title") }</h4>
									<input disabled={title?.length >= 200 ? true : false} onFocus={() => setBookManager('name')} className='input_seller_text' autoFocus placeholder={t("dashboard_bookcreate.name_of_book")} value={title} onChange={(e) => setTitle(e.target.value)} />
									<div className='validate_input'>
										<div className='validateStrings'>
											<span className={`invalid`}>{title?.length}/50 { t("dashboard_edit_form.char") }</span>
											<span className=''>{ t("dashboard_edit_form.max") } 50</span>
										</div>
									</div>
								</div>
							</div>
							
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_bookcreate.author") }</h4>
									<input onFocus={() => setBookManager('author')} className='input_seller_text' autoFocus placeholder={t("dashboard_bookcreate.author")} value={author} onChange={(e) => setAuthor(e.target.value)} />
								</div>
							</div>
						
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.language") }</h4>
									<LanguageSelect handleLanguageSelect={languageSelect} />
								</div>
							</div>

							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.category") }</h4>
									{serverCategories?.length > 1 && <CategorySelect handleCategorySelect={selectCategory} categoryList={serverCategories} />}
								</div>
							</div>


							<h4 className='seller_title'>{ t("dashboard_edit_form.book_cover") }</h4>
							<div className='seller_profile_wrapper create_book_media'>
								<div className='profile'>
									<div className={`image_picker ${coverPhoto ? 'coverPhotoAvailable': ''}`}>
										{ coverPhoto ? <div className='remove_cover_photo_btn' onClick={() => setCoverPhoto(null)}>{ t("dashboard_bookcreate.change_photo") }</div>: ''}
										<input
											className='input_seller_text'
											type='file' accept='image/jpeg, image/png, image/gif'
											onChange={e => handleFileInput(e)}
											disabled ={coverPhoto? true: false}
										/>
										<div className='imagePicker_decoy'>
											{
												coverPhoto
													? 
													// <ReactCrop crop={crop}  aspect={1} onChange={c => setCrop(c)}>
														<img src={URL.createObjectURL(coverPhoto)} alt='book_cover'  />
													// </ReactCrop>
													: coverPhUrl 
														? <img src={coverPhUrl} alt='book cover' />
														: <BsCardImage />
											}

										</div>
									</div>
								</div>
							</div>


							<h4 className='seller_title'>{ t("dashboard_edit_form.book_file") }</h4>
							<div className='seller_profile_wrapper create_book_media'>
								<div className='profile'>
									<div className='image_picker'>
									{ bookFile ? <div className='remove_cover_photo_btn' onClick={() => setCoverPhoto(null)}>{ t("dashboard_bookcreate.change_file") }</div>: ''}
										<input
											className='input_seller_text'
											type='file' accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,"
											onChange={handleFileChange}
										/>

										{
											!bookFile
												?  <div className='imagePicker_decoy'><BsFileEarmarkMedicalFill /></div>
												:  <div className='imagePicker_decoy'><embed src={previewFile} width='100%' style={{ overflowY: 'hidden' }}  type="application/pdf" /></div>
										}

									</div>
								</div>
							</div>

							
							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.tags") } { t("dashboard_bookcreate.tag_sub_desc")  }</h4>
									<input disabled={tags?.length >= 5 ? true : false} className='input_seller_text' onFocus={() => setBookManager('tag')} onKeyPress={(e) => e.key === 'Enter' && controlTagList(e)} placeholder='Tags*' value={tagString} onChange={e => setTagString(e.target.value)} />
									<div className='validate_input'>
										<div className='validateStrings'>
											<span className={`invalid`}>{tags?.length} / 5</span>
											<span className=''>{ t("dashboard_edit_form.max") } 5</span>
										</div>
									</div>
								</div>

								<div className='profile_tags'>
									{
										tags?.map(tag => (
											<div key={tag} className='tag'>
												<span className='tag_name'>{tag}</span>
												<span onClick={() => removeTag(tag)} className='tag_remove_btn'><VscChromeClose /></span>
											</div>
										))
									}
								</div>
							</div>


							<div className='seller_profile_wrapper'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.price") }</h4>
									<input className='input_seller_text' 
										onFocus={() => setBookManager('price')}
										value={price}
										onChange={e => setPrice(e.target.value)}
										type="number"
										max={150000}
									/>
									<span className='float_input'>XAF</span>
									<div className='validate_input'>
										<div className='validateStrings'>
											{/* <span className={`invalid`}>Minimun 500</span> */}
											<span className=''>{ t("dashboard_edit_form.max") } 150,000</span>
										</div>
									</div>
								</div>
							</div>


							<div className='seller_profile_wrapper book_forminput_textarea'>
								<div className='profile'>
									<h4 className='seller_title'>{ t("dashboard_edit_form.desc") }</h4>
									<textarea className='book_text_area' placeholder={ t("dashboard_edit_form.desc_body") } value={description} onChange={e => setDescription(e.target.value)}>Culpa in ad anim consequat enim dolore occaecat veniam nostrud. Id et mollit nisi magna dolore aute exercitation nulla magna. Et consequat irure et excepteur et nisi do culpa deserunt mollit non excepteur id anim. Qui elit anim enim non nisi consectetur minim nulla duis tempor aute. Minim amet est est cillum ut in sit excepteur cupidatat esse ea ex.</textarea>
									<span className='book_description_count mobile'>**250 - 50000 { t("dashboard_edit_form.max") }</span>
									<span className='book_description_count desktop'>{ t("dashboard_edit_form.min") } {description?.length}/250</span>
								</div>
							</div>
							<div className='button_payment_request'>
								<button className='profile_control_btn create_book_button'>
									<span>{location.state?.name ? t("dashboard_bookcreate.state_edit_book")  : t("dashboard_bookcreate.state_create_book")}</span>
									{ isloading && <span className='book_submit_btn_loader'><Loader /></span> }
								</button>
								{/* {progress > 0 && <progress value={progress} max="100" />}
								{progress > 0 && <span>{progress}%</span>} */}
							</div>
							
						</form>
					</div>

				</div>

				<div className={`saving_book_loader ${isloading ? 'show_book_saving_loader' : ''}`}>
					<div>
						<Loader />
						<p>{ t("dashboard_bookcreate.saving") }</p>
					</div>
				</div>


				<div className='create_book_tooltip create_book_form'>
					<div>
						<div className='book_title container'>{ t("dashboard_bookcreate.create_bm") } </div>
						<h3 className='title book_manager_title'>{ t("dashboard_bookcreate.status") }</h3>
						<div className={`book_create_status ${!allGood ? 'publish_status_not_active' : ''}`}>{ t("dashboard_bookcreate.publish") }</div>
						<div className='status_live'>
							<p>{ t("dashboard_bookcreate.current_status") }</p>
							<span className='top_selling_tag'>{ t("dashboard_bookcreate.status_draft") }</span>
						</div>
						<h3 className='title book_manager_title'>{ t("dashboard_bookcreate.preview") }</h3>
						<div className='book_create_status preview_status'>{ t("dashboard_bookcreate.preview") }</div>
						<div className='tooltip_aside'>
							{
								bookManager === 'name'
									?
									<ToolTipBookCreate />
									: bookManager === 'tag'
									?
									<ToolTipBookCreateTag />
									: bookManager === 'price'
									?
									<ToolTipBookCreatePrice />
									:
									bookManager === 'author'
									?
									<ToolTipAuthor />
									:
									<></>
							}
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default BookCreate





const ToolTipBookCreate = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.title.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.title.desc") }</div>
				</div>
			</div>
		</>
	)
}
const ToolTipAuthor = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.author.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.author.desc") }</div>
				</div>
			</div>
		</>
	)
}
const ToolTipBookCreateTag = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.tag.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.tag.desc") }</div>
				</div>
			</div>
		</>
	)
}
const ToolTipBookCreatePrice = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='input_detail_for_input'>
				<div className='aside_tooltip_wrapper'>
					<div className='detail_head'>{ t("dashboard_bookcreate.aid.price.title") }</div>
					<div className='tooltip_content'>{ t("dashboard_bookcreate.aid.price.desc") }</div>
				</div>
			</div>
		</>
	)
}
