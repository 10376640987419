import React from 'react'
import './training.css';
import CoursesSection from './CoursesSection';
import Subscribe from './Subscribe';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll';

const Training = () => {
    const { t } = useTranslation();
  return (
    <div className='main_section'>
      <section className='hero_section'>
        <div className='hero_content'>
            <h6>{t('training_section.get_title')}</h6>
            <h1>{t('training_section.enroll_title_main')}<br/>{t('training_section.enroll_title_main2')}</h1>
            <h2>{t('training_section.enroll_subtitle')}<br/>{t('training_section.enroll_subtitle2')}</h2>
            <Link
             to='subscribeSection'
             smooth={true}
             duration={500}
            >
                <button className='btn_started'>{t('training_section.get_started')}</button>
            </Link>
        </div>
      </section>
       <CoursesSection/>
       <Subscribe id="subscribeSection"/>
    </div>
  )
}

export default Training
