import React from 'react'
import { useTranslation } from 'react-i18next'
import { RiDownloadLine } from 'react-icons/ri';
import { getCourses } from './courses';


const CoursesSection = () => {
    const { t } = useTranslation();
    const courses = getCourses(t);
 
  return (
    <div className='courses_section'>
      <div className='title_content'>
        <h6>{t('courses_section.subtitle')}</h6>
        <h1>{t('courses_section.main_title')}<br/><span>{t('courses_section.main_sub_title')}</span></h1>
      </div>
      <div className='course-grid'>
      {courses.map((course) => (
                    <div key={course.id} className='course-item'>
                        <img src={course.image} alt={course.description} />
                        <h2>{course.title}</h2>
                        <p>{course.description}</p>
                       {/* Here button to download the guide */}
                    </div>
                ))}
      </div>
      <button>{t('courses_section.download')} <RiDownloadLine className="download-icon" /></button>
    </div>
  )
}

export default CoursesSection
