import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardActions } from './OnboardActions';

const OnboardProfessionalInfo = ({ handle_save_request, index, handle_go_back }) => {
	const [education, setEducation] = useState('');
	const [description, setDescription] = useState('');
    const [isloading, setIsloading] = useState(false)

    const { t } = useTranslation()

    const data = {
        "bio": description,
    }

    const save_data = () => {
        setIsloading(true)
        handle_save_request(index, data)
    }

    const go_back =() => {
        handle_go_back(index)
      }
  
  return (
    <>
        <div className='seller_account_head type'>
            <h2>{ t("start_selling.title_pro") }</h2>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer education'>
                <input value={education} onChange={(e) => setEducation(e.target.value)} type='text' placeholder={ t("start_selling.profession") } />
            </div>
        </div>
        <div className='name_input_flex'>
            <div className='inputContainer'>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} type='text' placeholder={ t("start_selling.desc") } />
            </div>
        </div>
        <OnboardActions save_data={save_data} handle_go_back={go_back} isloading={isloading} />
    </>
  )
}

export default OnboardProfessionalInfo