import React from 'react'
import { BsGift } from 'react-icons/bs'

import { ReactComponent as HelpSvg } from '../../../Assets/img/help_svg.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const RequestSubmitted = () => {
    const { t } = useTranslation()

  return (
    <div className='container'>
        <div className='request_sent_confirmation'>
            <h4 className='rs_heading'>{ t("faqs.request.title") }</h4>
            <div className='rs_svg'>
                    <HelpSvg />
            </div>

            <div className='rs_notice'>
            { t("faqs.request.desc_one") }
                <p>{ t("faqs.request.desc_two") }</p>
            </div>
            <div className='back_to_login'><Link to='/login' className='back_to_login'>{ t('auth_title.back_to_login') }</Link></div>
        </div>
    </div>
  )
}

export default RequestSubmitted