import React, { useEffect, useLayoutEffect, useState } from 'react'
import './Checkout.css'

import { AiFillStar } from 'react-icons/ai';

import FlutterWaveButton from './FlutterWaveButton';
import useLocalStorage from '../lib/UseLocalStorage';
import { getRequest, postRequest } from '../../Resquest';
import CheckoutCartItem from './CheckoutCartItem';
import { FiLoader } from 'react-icons/fi';


import {ReactComponent as Loading} from './loader.svg'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const Checkout = ({ user }) => {
    const [cart, setCart] = useLocalStorage('Cart')
    const [serverCartList, setServerCartList] = useState()
    const [cartItemList, setCartItemList] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [coupon, setCoupon] = useState('')
    const [verifyingCoupon, setVerifyingCoupon] = useState(false)
    const [couponResponse, setCouponResonse] = useState('')
    const [transaction_id, setTransaction_id] = useState('')

    const navigate = useNavigate()
    
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.access}`
        }
    }

    useEffect(() => {
        setIsLoading(true)
        serveCart()
    }, [])
    

    /**
     * Structure cart request object to send cart to the server 
     */
    const serveCart = async() => {
        let cartList = []
        cart.forEach(element => {
            cartList.push(element?.slug);
        });
        const productList = {
            "products": cartList
        }
        await request(productList)
        
    }
    
    /**
     * Sends the list of products in cart to the server
     * @param { slug array } productList product list object 
     */
    const request = async (productList) => {
        const res = await postRequest('/cart-item/', productList)
        res?.status === 200 && (
            saveGetCartData(res?.data)
        )
        if(res.data?.cart){
            if (res.data?.cart?.items.length < cart.length){
                setTimeout(() => {
                    alert( t("alerts.cart_less_checkout") )
                }, 3000);
            }

        }
    }
    

    /**
     * Save the cart list from the backend and the transaction id used for payment
     * @param {JSON} data Response from sending book to server
     */
    const saveGetCartData = (data) => {
        setServerCartList(data?.cart)
        setTransaction_id(data?.transaction_id)
        setIsLoading(false)
    }


    /**
     * Checks if coupon code entered by the user is valid or not
     */
    const verifyCoupon = async() => {
        setVerifyingCoupon(true)
        const disObject = {"coupon": coupon}
        const res = await postRequest('/get-discount/', disObject)
        setVerifyingCoupon(false)

        console.log(res);
        res.status === 202 && valid_coupon()
        res.status === 208 && setCouponResonse('in_use')

        res.response.status === 401 && setCouponResonse('invalid')
        res.status === 406 && setCouponResonse('invalid')
        res.status === 404 && setCouponResonse('invalid')
        if(couponResponse === 'invalid'){
            setTimeout(() => {
                setCouponResonse('')
            }, 3000);
        }

        
    }

    /**
     * Make a new request to update the cart to cart with valid coupon and total
     */
    const valid_coupon = () => {
        setCouponResonse('valid')
        serveCart()
    }


    /**
     * Make request to buy free books from hooyia server
     */
    const getFreeBooks = async() => {
        const dataset = {
            "tx_ref": transaction_id,
            "status": 'FREEACCEPTED'
        }
        const response = await postRequest('/order-treatment/', dataset)
        response?.status === 202 ?
        window.location.href = `/payment-status?status=sucessful&tx_ref=${ transaction_id }&free=true`
        :
        window.location.href = `/payment-status?status=fail&tx_ref=${ transaction_id }&free=true`
        
    }

    const { t } = useTranslation()

  return (
    <div className='checkoutPage'>
        <div className='container'>
            <div className='checkout_flex'>
                <div className='checkout_main'>
                    <div className='title'>{ t("cart_page.checkout_u") }</div>
                    <div className='checkout_item'>
                        {
                            isLoading
                            ?
                            <div className='loading_loader'>
                                <FiLoader />
                            </div>
                            :
                            serverCartList?.items.map(cartItem => (
                                <div key ={cartItem?.ebook?.slug }>
                                    <CheckoutCartItem book={cartItem} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='checkout_aside'>
                    {
                        !isLoading && (
                            <>
                                { verifyingCoupon && <Loader /> }
                                <div className='title'>{ t("cart_page.total") }</div>
                                <div className='checkout_aside_list'>
                                    <div className='title'>{ t("cart_page.sub_total") }</div>
                                    <div className='price'>XAF { serverCartList?.initial_total_price_ebook }</div>
                                </div>
                                <div className='checkout_aside_list'>
                                    <div className='title'>{ t("cart_page.cart_count") }</div>
                                    <div className='price'>{ serverCartList?.total_number_ebook }</div>
                                </div>
                                <div className='checkout_aside_list'>
                                    <div className='title'>{ t("cart_page.reduction") }</div>
                                    <div className='price'>{ serverCartList?.final_total_price_and_reduction[1] }</div>
                                </div>
                                <div className='checkout_aside_list'>
                                    <div className='title'>{ t("cart_page.final_total") }</div>
                                    <div className='price'>{ serverCartList?.final_total_price_and_reduction[0] }</div>
                                </div>
                                {
                                    couponResponse === 'invalid' 
                                    ?
                                    <input type='text' placeholder={ t("cart_page.coupon_code") }  value={t("cart_page.coupon_invalid")} disabled className='coupon_code_input invalid' />
                                    :
                                    couponResponse === 'valid'
                                    ?
                                    <input type='text' placeholder={ t("cart_page.coupon_code") }  value={t("cart_page.coupon_accept")} disabled className='coupon_code_input valid' />
                                    :
                                    couponResponse === 'in_use' ?
                                    <input type='text' placeholder={ t("cart_page.coupon_code") }  value={t("cart_page.coupon_in_use")} disabled className='coupon_code_input in_use' />
                                    :
                                    ""
                                    // <input type='text' placeholder={ t("cart_page.coupon_code") }  value={"Invalid coupon code"} disabled className='coupon_code_input invalid' />

                                }
                                <div className='coupon_form'>
                                    <input type='text' disabled={serverCartList?.initial_total_price_ebook === 0} placeholder={ t("cart_page.coupon_code") } onKeyPress={(e) => e.key === 'Enter' && verifyCoupon()} onBlur={verifyCoupon} value={coupon} onChange={e=> setCoupon(e.target.value)} className='coupon_code_input' />
                                    <input type='submit' className='verify_coupon_btn' onClick={verifyCoupon} value={t("cart_page.verify")}  />
                                </div>
                                <div className='checkout_main_methods'>
                                    { 
                                        serverCartList?.initial_total_price_ebook === 0 ?
                                        <button onClick={getFreeBooks} className=''>{ t("cart_page.free_confirm_btn") }</button>
                                        :
                                        <FlutterWaveButton amount={serverCartList?.final_total_price_and_reduction[0] } transaction_id={ transaction_id } />

                                    }
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    </div>
  )
}

export default Checkout

export const Loader = () => {
    return(
        <div className='coupon_loader'>
            <Loading />
        </div>
    )
}

