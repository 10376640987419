import React from 'react';
import { useTranslation } from 'react-i18next';
import { getValues } from './location/values';

const WhatWeDoSection = () => {
  const { t } = useTranslation();
  const values = getValues(t); // Pass t to the function to get translated values

  return (
    <div className='WhatWeDoSection'>
      <h2 className='section_title'>{t('about_page.values.main_title')}</h2>
      <div className='card-grid'>
        {values.map((card) => (
          <div key={card.id} className='card-item'>
            <img src={card.image} alt={card.title} />
            <h2>{card.title}</h2>
            <p>{card.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatWeDoSection;
