import React from 'react'
import { useTranslation } from 'react-i18next'

const AboutUsSection = () => {
    const { t } = useTranslation()
    
  return (
    <section className='landing_page_about_us_section'>
        <div className='container'>
            <div className='about_section_flex'>
                <div className='lists'>
                    <h1>{ t('about_page.service') }<br/>  <span className='about_page_break'> { t('new_about_page.why_choose_us.content', {defaultValue: "HooYia ?"}) } </span></h1> 
                   
                </div>
                <div className='about_contact_form'>
                    <h3>{ t('about_page.why_choose_us.send_message') }</h3>
                    <div className='contact_input'>
                        <input type='text' placeholder={ t('about_page.why_choose_us.name') } />
                    </div>
                    <div className='contact_input'>
                        <input type='text' placeholder={ t('about_page.why_choose_us.email') } />
                    </div>
                    <div className='contact_input'>
                        <input className='submit_home_contact_form' type='submit' value={ t('about_page.why_choose_us.send') } />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AboutUsSection