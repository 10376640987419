
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react'
import { VscChromeClose } from 'react-icons/vsc'
import useLocalStorage from '../lib/UseLocalStorage';
import { useTranslation } from 'react-i18next';

import Switch from "react-switch";
import { tabTitle } from '../..';


const variants = {
	hidden: {
		y: "10vh",
		opacity: 0,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		}
	},
	visible: {
		y: "0vh",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		}
	},
	exit: {
		y: "10vh",
		opacity: 0,
		
	}
}


const Settings = ({ user }) => {
	const [deleteModal, setDeleteModal] = useState(false)
	const [isDarkTheme, setIsDarkTheme] = useLocalStorage('theme', false)
	const [checked, setChecked] = useState(false)
	const { t } = useTranslation()
  
  const deleteAccount = () => {

  }
  const deleteModalState = () => {
		setDeleteModal(true)
		setTimeout(() => {
			setDeleteModal(false)
		}, 7000);
	}
  const toggleTheme = () => {
    // setIsDarkTheme(!isDarkTheme)
    // window.location.reload()
	setChecked(true)
	setTimeout(() => {
		alert(t("settings.theme_alert"))
	}, 400);
	setTimeout(() => {
		setChecked(false)
	}, 1000);
  }

  tabTitle(`${user?.user?.first_name} ${user?.user?.last_name} parametre`)
  return (
    <>
      <div className='settings_page'>
          <div className='settings_container'>
            <h2 className='title'>{ t("settings.title") }</h2>
            <br />
            <div className='configs'>
              <div className='config_row'>
                <span className='key'>{ t("settings.theme") }</span>
                <div className='value'>
					<span className='visual'>
				  		<Switch width={35} height={15} activeBoxShadow={'0 0 2px 3px #0000'} onChange={ toggleTheme } checked={checked} />
					</span>
                </div>
              </div>
              <div className='config_row'>
                <span className='key'>{ t("settings.account") }</span>
                <div className='value'>
                  <button onClick={deleteModalState} className='delete_account'>{ t("modals.delete_account.btn") }</button>
                </div>
              </div>
            </div>
          </div>
      </div>
      <AnimatePresence
				initial={false}
				mode='wait'
			>
				{
					deleteModal && (
						<div className='book_modalWrapper'>
							<motion.div
								className='book_notify_modal book_deleteModal'
								variants={variants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<div>
									<p className='modal_notify_text'>{ t("modals.delete_account.text") }</p>
									<button onClick={deleteAccount}>{ t("modals.delete_account.btn") }</button>
								</div>
								<span className='close_icon' onClick={() => setDeleteModal(false)}><VscChromeClose /></span>
							</motion.div>
						</div>
					)
				}
			</AnimatePresence>
    </>
  )
}

export default Settings
