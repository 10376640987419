import idea from "../../../../Assets/img/about/idea.png";
import atom from "../../../../Assets/img/about/atom.png";
import badge from "../../../../Assets/img/about/badge.png";
import learning from "../../../../Assets/img/about/learning.png";
import technical from "../../../../Assets/img/about/technical-support.png";
import leader from "../../../../Assets/img/about/leader.png";


import christian from "../../../../Assets/img/about/christian.jpg";
import boris from "../../../../Assets/img/about/boris.jpg";
import belviane from "../../../../Assets/img/about/belviane2.jpg";
import MrDonald from "../../../../Assets/img/about/donald.jpeg";




export const getValues = (t) => [
    {
        id: 1,
        title: t('about_page.values.title1'),
        image: idea,
        content: t('about_page.values.desc1')  
    },
    {
        id: 2,
        title:t('about_page.values.title2'),
        image: learning,
        content: t('about_page.values.desc2')
    },
    {
        id: 3,
        title: t('about_page.values.title3'),
        image: technical,
        content:  t('about_page.values.desc3')
    },
    {
        id: 4,
        title:t('about_page.values.title4'),
        image: atom,
        content: t('about_page.values.desc4')
    },
    {
        id: 5,
        title: t('about_page.values.title5'),
        image: badge,
        content: t('about_page.values.desc5')
    },
    {
        id: 6,
        title:t('about_page.values.title6'),
        image: leader,
        content: t('about_page.values.desc6')
    },

]


export const team = [
    {
        id: 1,
        image:MrDonald,
        name:"Tedom Noutchogouin Donald"
    },
    {
        id: 2,
        image:belviane,
        name:"Nogho Tidang Belviane"
    },
    {
        id: 3,
        image:christian,
        name:"Ndongmo Nguimfack Christian"
    },
    {
        id: 4,
        image:boris,
        name:"Fotsing Tchoupe Mathieu Boris"
    }
]