import React from 'react'
import { AiFillStar } from 'react-icons/ai';


import medias from './book.jpg'
import { useTranslation } from 'react-i18next';

const CheckoutCartItem = ({ book }) => {
        const stars = new Array(5).fill(0)
        const { t } = useTranslation()
        return (
            <div className="cartItemWrapper">
                <div className="cartFlexItem cartImageWrapper">
                    <img src={book?.ebook?.cover} alt={ book?.ebook?.name } />
                </div>
                <div className="cartFlexItem cartDescription">
                    <h3 className="title">{ book?.ebook?.name }</h3>
                    {/* <h3 className="cartItemName">{ book?.ebook?.description }</h3> */}
                    <div className="rating remove">
                        {stars.map((_, index)=>(
                            <AiFillStar key = {index} />
                        ))}
                        <div className="cartNumbers remove">{ t("cart_page.quantity") } {book?.quantity}</div>
                    </div>
                    <div className="cartMobile_price">{ book?.ebook?.price }</div>
                </div>
                <div className="cartFlexItem cartControl">
                    <div className="cartPrice">{ book?.ebook?.price }</div>
                    <div className="cartBtnControl">
                        <span className="cartBtn cartSaveToLater remove">{ t("cart_page.add_btn") }</span>
                    </div>
                </div>
            </div>
        )
    }
export default CheckoutCartItem