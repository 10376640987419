import React, { useState } from 'react'
import './Faq.css'
import { RiSearch2Line } from 'react-icons/ri'
import FaqQuestion from './FaqQuestion'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const Faq = () => {
        const [showSupportModal, setShowSupportModal] = useState(false);
        
        const { t } = useTranslation();
  return (
    <div className='frequently_asked_question'>
        <div className='heroSection'>
                <div className='container'>
                        <h1 className='hero_title'>{ t('faqs.title') }</h1>
                        <p className='hero_title'>{ t('faqs.title_two') }</p>
                        <div className='search_faq_wrapper_n'>
                                <div className='search_faq_n'>
                                        <input type='text' className='faq_search_input' placeholder={ t('faqs.search_placeholder') } />
                                        <div className='searchIcon'>
                                                <RiSearch2Line />
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <div className='container'>
                <div className='frequent_asked_question_list'>
                        <FaqQuestion question="How to unblock my account" answer="your account has been permanently banded, you can no longer access this site" />
                        <FaqQuestion question="How to create an account" answer="click on the login button and enter your login credentials to continue. " />
                        <div className='faq_need_help_btn'>
                                <p>{ t('faqs.more_help') }</p>
                                <Link to="/help/contact-support" className='faq_btn_n'>{ t('faqs.contact_us') }</Link>
                        </div>
                </div>
        </div>
    </div>
  )     
}

export default Faq
