import React, { useLayoutEffect, useState } from 'react'
import './Profile.css'
import { getRequest } from '../../Resquest'
import ProductGrid from '../Products/ProductGrid'
import { useTranslation } from 'react-i18next'
import { tabTitle } from '../..'
const UserProfile = ({ user, username }) => {
        const [baseMe, setBaseMe] = useState(user?.user?.seller_ebook_shop_url)
        const [userData, setUserData] = useState({})
        const [isloading, setIsloading] = useState(false)
        const [ebooks, setEbooks] = useState([])
        const [store_owner, setStore_owner] = useState(true)


        useLayoutEffect(() => {
                setIsloading(true)
                fetchPublishedBooks()
        }, [])


        const fetchPublishedBooks = async() => {
                const base = baseMe.split('me/')[1]
                let endpoint = "/ebook-store/"+ base;
                console.log(endpoint);
                request(endpoint)
        }


        const request = async(endpoint) => {
                const res = await getRequest(endpoint)
                res.status === 200 && saveResponse(res.data)
                console.log(res);
        }

        const saveResponse = (data) => {
                setUserData(data)
                setEbooks(data?.ebooks)
                setIsloading(false)
        }


        tabTitle(`${user?.user?.first_name} ${user?.user?.last_name} HooYia store`)
        const { t } = useTranslation()
  return (
    <div className='user_profile'>
        <div className='sellerProfile_head'>
                <div className='container flex_profile_head'>
                        <div className='seller_profile_profilePic profilePic'>
                                {
                                        user?.user?.profile?.pp 
                                        ?
                                        <img src={user?.user?.profile?.pp } alt={ user?.user?.first_name }  />
                                        :
                                        <p>{ (user?.user?.first_name).charAt(0) } - { (user?.user?.last_name).charAt(0)  }</p>
                                }
                        </div>
                        <div className='profile_title'>
                                <>
                                        <h3 className='title_profile'>{ user?.user?.first_name + ' ' + user?.user?.last_name }</h3>
                                        <p style={{ textTransform: 'lowercase'}}>{ user?.user?.email}</p>
                                        <h4 className='text_bold'>{ t("dashboard_edit_form.address") }: { user?.user?.profile?.address_line_one} - { user?.user?.profile?.city } - { user?.user?.profile?.country__name }</h4>
                                </>
                        </div>
                </div>
        </div>
        <div className='profile_about'>
                <p className='text_bold'>{ t("dashboard_edit_form.about") }</p>
                <p>{ user?.user?.profile?.bio}</p>
        </div>

        <div className='profile_body'>
                <div className='container'>
                        <h3 className='title_profile'>{ t("alerts.store_title") }</h3>
                        <ProductGrid products={ebooks} isloading={isloading} />
                </div>
        </div>
        
    </div>
  )
}

export default UserProfile
