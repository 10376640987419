import React from 'react'
import './explore.css'
import CustomerSupport from '../../../../Assets/img/customer_support.jpg'
import MarketPlace from '../../../../Assets/img/reading.jpg'
import UseCase from '../../../../Assets/img/learning.jpg'
import Membership from '../../../../Assets/img/blue.jpg'
import { FiChevronsRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Explore = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  return (
    <div className='explore_page'>
        <div className='landing_explore'>
          <div className='container'>
            <h1>{ t("explore.title") }</h1><br />
            <p>{ t("explore.title_desc") }</p>
          </div>
        </div>
        <div className=''>
          {/* --------------------- CUSTOMER SERVICE ---------------------- */}
          <section className='explore_main_flex'>
            <div className='explore_main_content'>
              <div className='content_wrapper right'>
                <h1>{ t("explore.customer_service.title") }</h1>
                <p>{ t("explore.customer_service.desc") } </p>
                <div className='explore_main_btns'>
                  <button onClick={()=> navigate('/explore/customer-service')} className='learn_more'>
                    <span>Learn more </span>
                  </button>
                </div>
              </div>
            </div>
            <div className='explore_main_img'>
              <img src={CustomerSupport} className='explore_main_img_img' alt='customer service lady' />
            </div>
          </section>

          {/* --------------------- MARKET PLACE --------------------------- */}
          <section id="customer-service" className='explore_main_flex'>
            <div className='explore_main_img'>
              <img src={ MarketPlace } className='explore_main_img_img' alt='customer service lady' />
            </div>
            <div className='explore_main_content'>
              <div className='content_wrapper'>
                <h1>{ t("explore.market_place.title") }</h1>
                <p>{ t("explore.market_place.desc") }</p>
              </div>
            </div>
          </section>

          {/* --------------------- USE CASE ------------------------------- */}
          <section id="use-case" className='explore_main_flex'>
            <div className='explore_main_content'>
              <div className='content_wrapper right'>
                <h1>{ t("explore.use_case.title") }</h1>
                <p>{ t("explore.use_case.desc") }</p>
              </div>
            </div>
            <div className='explore_main_img'>
              <img src={UseCase} className='explore_main_img_img' alt='customer service lady' />
            </div>
          </section>

          {/* --------------------- MEMBERSHIP ------------------------------- */}
          <section id='membership' className='explore_main_flex'>
            <div className='explore_main_img'>
              <img src={ Membership } className='explore_main_img_img' alt='customer service lady' />
            </div>
            <div className='explore_main_content'>
              <div className='content_wrapper'>
                <h1>{ t("explore.membership.title") }</h1>
                <p>{ t("explore.membership.desc") } </p>
                <button onClick={()=> navigate('/register')} className='learn_more'>
                  <span>Join Us </span>
                </button>
              </div>
            </div>
          </section>
        </div>
    </div>
  )
}

export default Explore