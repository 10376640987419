import front from "../../../Assets/img/courses/atom.png"
import back from "../../../Assets/img/courses/backend-coding.png"
import data from "../../../Assets/img/courses/data.png"
import machine from "../../../Assets/img/courses/brain.png"
import mobile from "../../../Assets/img/courses/mobile-development.png"
import robots from "../../../Assets/img/courses/robot.png"
 



export const getCourses = (t) => [
    {
        id: 1,
        image: front,
        title: t('courses_section.front-title'),
        description: t('courses_section.front-end')
    },
    {
        id: 2,
        image: back,
        title: t('courses_section.backend-title'),
        description: t('courses_section.back-end')
    },
    {
        id: 3,
        image: data,
        title: t('courses_section.data-science-title'),
        description: t('courses_section.data-science')
    },
    {
        id: 4,
        image: machine,
        title: t('courses_section.machine-learning-title'),
        description: t('courses_section.machine-learning')
    },
    {
        id: 5,
        image: mobile,
        title: t('courses_section.mobile-title'),
        description: t('courses_section.mobile-dev')
    },
    {
        id: 6,
        image: robots,
        title: t('courses_section.robotics-title'),
        description: t('courses_section.robotics')
    },
]