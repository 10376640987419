import React from 'react'

const ChatMessages = ({ messages }) => {
  const my_sender_id = 1
  return (
    <div>
      {
        messages.map((message, index) => (
          <div className='messageText' key={index}>
            <p className={`text ${message.sender_id === my_sender_id ? 'meSend' : 'noMeSend'}`}>
              {message.message}
              <span className='message_time'>{message.time}</span>
            </p>
          </div>
        ))
      }
    </div>
  )
}

export default ChatMessages
