import React, { useLayoutEffect, useState } from 'react'
import './Profile.css'
import { getRequest } from '../../Resquest'
import ProductGrid from '../Products/ProductGrid'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { tabTitle } from '../..'


const StoreProfile = ({ user, username }) => {
        const [userData, setUserData] = useState({})
        const [isloading, setIsloading] = useState(false)
        const [ebooks, setEbooks] = useState([])
        const [fname, setFname] = useState('')
        const [lname, setLname] = useState('')
        const [searchString, setSearchString] = useState('')
        const [lang, setLang] = useState('en')

        useLayoutEffect(() => {
            setIsloading(true)
            get_store()
            getLang()
        }, [])

        const getLang = () => {
                setLang(i18next.language)
        }

        const get_store = () => {
                const username = window.location.pathname.split('/me/')[1]
                let endpoint = "/ebook-store/"+ username;
                request(endpoint)
        }


        const request = async(endpoint) => {
                const res = await getRequest(endpoint)
                res.status === 200 && saveResponse(res.data)
        }
            
        const saveUserData = ( user) => {
            setFname(user.name.split(' ')[0])
            setLname(user.name.split(' ')[1])
        }

        const saveResponse = (data) => {
            saveUserData(data.user)
            setUserData(data)
            setEbooks(data?.ebooks)
            setIsloading(false)
        }

        tabTitle(`${fname} ${lname} HooYia store`)
        
        const { t } = useTranslation()
  
        return (
    <div className='user_profile'>
        <div className='sellerProfile_head'>
                {
                        userData?.user
                        ?
                        <div className='container flex_profile_head'>
                                <div className='seller_profile_profilePic profilePic'>
                                {
                                        userData?.user?.pp 
                                        ?
                                        <img src={userData?.user?.pp } alt='book_cover'  />
                                        :
                                        <p>{ (fname).charAt(0)} - { (lname).charAt(0)  }</p>
                                }
                                        
                                </div>
                                <div className='profile_title'>
                                        { 
                                        <>
                                                <h3 className='title_profile'>{fname} {t("footer.store")}</h3>
                                                <p style={{ textTransform: 'lowercase'}}>{ userData?.user?.email }</p>
                                                <h2>{t("dashboard_edit_form.address")}:{ userData?.user?.city } - { userData?.user?.country }</h2>
                                        </>
                                
                                        }
                                </div>
                        </div>
                        :
                        <div className='container flex_profile_head'>
                                <h3>{ t("randoms.store_not_found") }</h3>
                        </div>

                }
        </div>
        <div className='profile_about'>
                <h2><b>{t("dashboard_edit_form.about")}: { userData?.user?.name }</b></h2>
                <p>{ userData?.user?.bio }</p>
        </div>

        {
                userData?.user && (
                        <>
                        <div className='profile_body'>
                                <div className='container'>
                                        {console.log(lang)}
                                        <h3 className='title_profile' style={{ marginBottom: '0px' }}>{lang === 'en' ? <span> Welcome to {fname}'s Shop </span> :  <span> Bienvenue dans la boutique de {fname} </span> }</h3>
                                        <h3 className='title_profile' style={{ marginTop: '0px' }}>{ t("alerts.store_title") }</h3>
                                        <div className='filter_store_wrapper'>
                                                <span className='filter_heading'>Filter</span>
                                                <input type='text' disabled={ebooks.length <= 0 } className='filter_store_input' onChange={e => setSearchString(e.target.value)} value={searchString} placeholder='search...' />
                                        </div>
                                        {
                                                ebooks.length > 0
                                                ?
                                                <ProductGrid products={ebooks} isloading={isloading} />
                                        :
                                                <div>{t("no_books_to_show")}</div>
                                        }
                                </div>
                        </div>
                        
                        </>
                )
        }
    </div>
  )
}

export default StoreProfile
