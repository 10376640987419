import { createContext } from "react";
import useLocalStorage from "../Components/lib/UseLocalStorage";

export const IsSellercontext = createContext()

export function IsSellerContextProvider({ children }) {
    const [isSellerAccount, setIsSellerAccount] = useLocalStorage(true)

    return(
        <IsSellercontext.Provider value={[isSellerAccount, setIsSellerAccount]} >
            {children}
        </IsSellercontext.Provider>
    )    
}