
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'
import { useNavigate } from 'react-router'

const variants = {
    hidden: {
        y: "10vh",
        opacity: 0,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    visible: {
        y: "0vh",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }
    },
    exit: {
        y: "10vh",
        opacity: 0,

    }
}


const PopupAddToCart = ({ showPopup, handleRemoveModal, text, cart }) => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <AnimatePresence
            initial={false}
            mode='wait'
        >
            {
                showPopup && (
                    <div className='book_modalWrapper'>
                        <motion.div
                            className='book_notify_modal'
                            variants={variants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                                <div className='add_to_cart_modal_inner'>
                                        <p className='modal_notify_text' >{ text }</p>
                                        {cart == true && <button onClick={() => navigate("/cart")}>{ t("modals.add_to_cart.btn")}</button>}
                                </div>
                            <span onClick={() => handleRemoveModal}><VscChromeClose /></span>
                        </motion.div>
                    </div>
                )
            }
        </AnimatePresence>
    )
}

export default PopupAddToCart
